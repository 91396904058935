export const MissionType = {
  WAYLINE: 'wayline',
  DFR: 'dfr',
};

export const MissionStatus = {
  IDLE: 'idle',
  PLANNED: 'planned',
  READY: 'ready',
  PREPARING: 'preparing',
  EXECUTING: 'executing',
  PAUSED: 'paused',
  STOPPED: 'stopped',
  CANCELED: 'canceled',
  FAILED: 'failed',
  COMPLETED: 'completed',
  RETURNING: 'returning',
};

export const NotActiveMissionStatusSet = [
  MissionStatus.IDLE, 
  MissionStatus.FAILED, 
  MissionStatus.COMPLETED,
  MissionStatus.CANCELED,
  MissionStatus.STOPPED,
];

export const onboardWaypointAction = {
  UPLOAD: 1,
  START: 2,
  PAUSE: 3,
  RESUME: 4,
  STOP: 5,
  SET_INFO: 6,
  GET_INFO: 7,
  MISSION_INIT: 20,
  MISSION_TERMINATE: 21,
  MISSION_QUERY: 22,
};

export const WaypointStateV3 = {
  IDLE: 0, 
  PREPARE: 16, 
  TRANS_MISSION: 32,
  MISSION: 48,
  BREAK: 64,
  RESUME: 80,
  RETURN_FIRSTPOINT: 98
}

export const WaypointStateTextV3 = {
  [WaypointStateV3.IDLE]: 'Idle',
  [WaypointStateV3.PREPARE]: 'Drone is checking the mission...',
  [WaypointStateV3.TRANS_MISSION]: 'Ascending to optimal altitude, please wait.',
  [WaypointStateV3.MISSION]: 'Mission is now running...',
  [WaypointStateV3.BREAK]: 'Mission paused...',
  [WaypointStateV3.RESUME]: 'Mission resumed...',
  [WaypointStateV3.RETURN_FIRSTPOINT]: 'Returning to the first point...',
}

export const WaypointStateV2 = {
  GROUND_STATION_NOT_START: 0,
  MISSION_PREPARED: 1,
  ENTER_MISSION: 2,
  EXECUTE_FLYING_ROUTE_MISSION: 3,
  PAUSE_STATE: 4,
  ENTER_MISSION_AFTER_ENDING_PAUSE: 5,
  EXIT_MISSION: 6,
};

export const WaypointStateTextV2 = {
  [WaypointStateV2.GROUND_STATION_NOT_START]: 'Ground station not start',
  [WaypointStateV2.MISSION_PREPARED]: 'Mission uploaded',
  [WaypointStateV2.ENTER_MISSION]: 'Mission started',
  [WaypointStateV2.EXECUTE_FLYING_ROUTE_MISSION]: 'Mission flying',
  [WaypointStateV2.PAUSE_STATE]: 'Mission paused',
  [WaypointStateV2.ENTER_MISSION_AFTER_ENDING_PAUSE]: 'Mission resumed',
  [WaypointStateV2.EXIT_MISSION]: 'Mission exit happend',
};

export const OnboardWaypointEvent = {
  MISSION_INTERRUPT_EVENT: 1,
  MISSION_RESUME_EVENT: 2,
  MISSION_STOP_EVENT: 3,
  MISSION_ARRIVAL_EVENT: 16,
  MISSION_FINISHED_EVENT: 17,
  MISSION_OBSTACLE_EVENT: 18,
  MISSION_SWITCH_EVENT: 48,
};

export const OnboardWaypointEventCollection = {
  MISSION_INTERRUPT_EVENT: {
    id: 1,
    message: 'Mission inteterrupt',
  },
  MISSION_RESUME_EVENT: {
    id: 2,
    message: 'Mission resumed',
  },
  MISSION_STOP_EVENT: {
    id: 3,
    message: 'Mission stopped',
  },
  MISSION_ARRIVAL_EVENT: {
    id: 16,
    message: 'Mission arrived at point',
  },
  MISSION_FINISHED_EVENT: {
    id: 17,
    message: 'Mission finished',
  },
  MISSION_OBSTACLE_EVENT: {
    id: 18,
    message: 'Mission obstacle detected',
  },
  MISSION_SWITCH_EVENT: {
    id: 48,
    message: 'Mission switch happened',
  },
};

export const WaypointStateCollectionV2 = {
  GROUND_STATION_NOT_START: {
    id: 0,
    message: 'Ground station not start',
  },
  MISSION_PREPARED: {
    id: 1,
    message: 'Mission uploaded',
  },
  ENTER_MISSION: {
    id: 2,
    message: 'Mission started',
  },
  EXECUTE_FLYING_ROUTE_MISSION: {
    id: 3,
    message: 'Mission flying',
  },
  PAUSE_STATE: {
    id: 4,
    message: 'Mission paused',
  },
  ENTER_MISSION_AFTER_ENDING_PAUSE: {
    id: 5,
    message: 'Mission resumed',
  },
  EXIT_MISSION: {
    id: 6,
    message: 'Mission exit happend',
  },
};

export const DjiCloudMissionWaylineState = {
  DISCONNECTED: 0,
  NOT_SUPPORTED: 1,
  PREPARATION: 2,
  UPLOADING: 3,
  TRIGGER_START: 4,
  ENTERING: 5,
  EXECUTION: 6,
  INTERRUPTED: 7,
  RECOVERY: 8,
  COMPLETED: 9,
};

export const DjiCloudMissionWaylineStateText = {
  0: "Disconnected",
  1: "This waypoint is not supported",
  2: "Wayline preparation state. It can upload files and execute existing files",
  3: "Wayline file uploading",
  4: "Trigger start command. The aircraft triggers logic such as reading the wayline. The mission has not started yet and it's in a preparation state",
  5: "Entering the wayline, heading to the first waypoint",
  6: "Wayline execution",
  7: "Wayline interrupted. Triggered conditions are 1 for User actively pauses and 2 for Flight control abnormality",
  8: "Wayline recovery",
  9: "Wayline completed"
};


export const DjiCloudMissionWaylineStep = {
  INITIAL_STATE: 0,
  PRE_FLIGHT_CHECK_AIRCRAFT: 1,
  PRE_FLIGHT_CHECK_DOCK: 2,
  WAYLINE_EXECUTION: 3,
  RETURN_TO_HOME: 4,
  WAYLINE_PREPARATION: 5,
  DOCK_WORKING_STATE: 6,
  STARTUP_CHECK: 7,
  REMOTE_IMAGE_TRANSMISSION: 8,
  WAITING_FLIGHT_SYSTEM: 9,
  WAITING_RTK_SOURCE: 10,
  CHECK_RTK_SOURCE: 11,
  WAITING_FLIGHT_CONTROL: 12,
  DOCK_CONTROL: 13,
  CUSTOM_FLIGHT_AREA: 14,
  OFFLINE_MAPS: 15,
  GET_LATEST_KMZ_URL: 16,
  DOWNLOAD_KMZ: 17,
  UPLOAD_KMZ: 18,
  COLORING_CONFIGURATION: 19,
  TAKEOFF_PARAMETER_SETTING: 20,
  FLYTO_TAKEOFF_PARAMETER: 21,
  TAKEOFF_DOCK_CHECK: 22,
  HOME_POINT_SETTING: 23,
  TRIGGER_WAYLINE_EXECUTION: 24,
  PRE_RETURN_TO_HOME: 26,
  AIRCRAFT_LANDING: 27,
  CLOSE_COVER: 28,
  DOCK_EXIT_WORKING: 29,
  DOCK_ABNORMAL_RECOVERY: 30,
  UPLOAD_FLIGHT_LOGS: 31,
  CHECK_CAMERA_RECORDING: 32,
  GET_MEDIA_FILES: 33,
  ABNORMAL_RECOVERY: 34,
  NOTIFY_MISSION_RESULT: 35,
  LOG_LIST_AIRCRAFT: 36,
  LOG_LIST_DOCK: 37,
  UPLOAD_LOG_LIST: 38,
  LOG_RETRIEVAL_AIRCRAFT: 39,
  LOG_RETRIEVAL_DOCK: 40,
  COMPRESS_AIRCRAFT_LOGS: 41,
  COMPRESS_DOCK_LOGS: 42,
  UPLOAD_AIRCRAFT_LOGS: 43,
  UPLOAD_DOCK_LOGS: 44,
  NOTIFY_RESULT: 45,
  CUSTOM_FLIGHT_AREA_UPDATE: 46,
  CUSTOM_FLIGHT_AREA_UPDATING: 47,
  OFFLINE_MAP_UPDATE: 48,
  OFFLINE_MAP_UPDATING: 49,
  WAIT_SERVICE_RESPONSE: 65533,
  NO_SPECIFIC_STATE: 65534,
  UNKNOWN: 65535,
};

export const DjiCloudMissionWaylineStepText = {
  0: "Initial state",
  1: "Pre-flight check, whether the aircraft is executing a wayline",
  2: "Pre-flight check, whether the dock has exited working mode",
  3: "Pre-flight check, wayline execution in progress",
  4: "Pre-return-to-home check, in the process of returning to home",
  5: "Wayline execution enters the preparation state, waiting for the mission to be issued",
  6: "Dock enters working state",
  7: "Enter the startup check and cover preparation work",
  8: "Remote image transmission frequency Pairing",
  9: "Waiting for the flight system to be ready, push connection established",
  10: "Waiting for RTK source to listen for reported values",
  11: "Check if the RTK source is from the dock, if not, reset it",
  12: "Waiting for flight control notification",
  13: "The dock has no control and seizes the control of the aircraft",
  14: "Consistency check of custom flight area",
  15: "Consistency check of offline maps",
  16: "Get the latest KMZ URL",
  17: "Download KMZ",
  18: "KMZ uploading",
  19: "Coloring configuration",
  20: "Aircraft takeoff parameter setting, alternate landing point setting, takeoff altitude setting, coloring setting",
  21: "Aircraft flyto takeoff parameter setting",
  22: "Takeoff dock checks the landing dock preparation status",
  23: "Home point setting",
  24: "Trigger wayline execution",
  26: "Enter pre-return-to-home check",
  27: "Aircraft landing at the dock",
  28: "Close the cover after landing",
  29: "Dock exits working mode",
  30: "Dock abnormal recovery",
  31: "Dock uploads flight system logs",
  32: "Check camera recording state",
  33: "Get the number of media files",
  34: "Abnormal recovery after dock takeoff cover",
  35: "Notify mission result",
  36: "Log list retrieval - Aircraft list",
  37: "Log list retrieval - Retrieve the list of docks",
  38: "Log list retrieval - Upload log list result",
  39: "Log retrieval - Retrieve aircraft logs",
  40: "Log retrieval - Retrieve dock logs",
  41: "Log retrieval - Compress aircraft logs",
  42: "Log retrieval - Compress dock logs",
  43: "Log retrieval - Upload aircraft logs",
  44: "Log retrieval - Upload dock logs",
  45: "Log retrieval - Notify the result",
  46: "Custom flight area file update preparation",
  47: "Custom flight area updating",
  48: "Offline map update preparation",
  49: "Offline map updating",
  65533: "Wait for service response after completion",
  65534: "No specific state",
  65535: "UNKNOWN"
};

export const DjiCloudMissionWaylineBreakReason = {
  NO_ABNORMALITIES: 0,
  MISSION_ID_NOT_EXIST: 1,
  UNCOMMON_ERROR: 2,
  ERROR_LOADING_WAYLINE_FILE: 4,
  FAILED_QUERY_BREAKPOINT_FILE: 5,
  INCORRECT_CMD_PARAMETER: 6,
  TIMEOUT_PARSING_WPMZ: 7,
  WAYLINE_ALREADY_STARTED: 257,
  UNABLE_TO_INTERRUPT_WAYLINE: 258,
  WAYLINE_NOT_STARTED: 259,
  FLIGHT_MISSION_CONFLICT: 261,
  UNABLE_TO_RESUME_WAYLINE: 262,
  MAX_ALTITUDE_EXCEEDED: 513,
  MAX_DISTANCE_EXCEEDED: 514,
  ALTITUDE_LIMIT_TRIGGERED: 516,
  OBSTACLE_SENSING_TRIGGERED: 517,
  POOR_RTK_SIGNAL: 518,
  RESTRICTED_ZONE_BOUNDARY: 519,
  GEO_ZONE_ALTITUDE_LIMIT: 521,
  FAILED_TAKEOFF_REQUEST: 522,
  TAKEOFF_EXECUTION_FAILED: 523,
  FAILED_WAYLINE_REQUEST: 524,
  FAILED_WAYLINE_RTK_FIX_REQUEST: 526,
  WAYLINE_RTK_FIX_FAILED: 527,
  WEAK_GPS_SIGNAL: 769,
  RC_GEAR_NOT_IN_N_POSITION: 770,
  HOME_POINT_NOT_REFRESHED: 771,
  LOW_BATTERY_START_PREVENTED: 772,
  LOW_BATTERY_WAYLINE_INTERRUPTED: 773,
  RC_AIRCRAFT_DISCONNECTED: 775,
  GROUND_PROP_SPINNING: 778,
  CAMERA_STATE_ABNORMAL: 779,
  TERRAIN_FOLLOW_ALT_INVALID: 780,
  GLOBAL_MAP_CALC_ERROR: 781,
  STRONG_WINDS_RTH: 784,
  USER_EXIT: 1281,
  USER_INTERRUPTION: 1282,
  USER_TRIGGERED_RTH: 1283,
  INCORRECT_START_INFO: 1539,
  UNSUPPORTED_COORDINATE_SYSTEM: 1540,
  UNSUPPORTED_ALTITUDE_MODE: 1541,
  UNSUPPORTED_TRANSITIONAL_WAYLINE: 1542,
  UNSUPPORTED_YAW_MODE: 1543,
  UNSUPPORTED_YAW_REVERSAL: 1544,
  UNSUPPORTED_WAYPOINT_TYPE: 1545,
  COORDINATED_TURNING_INVALID: 1546,
  WAYLINE_GLOBAL_SPEED_INVALID: 1547,
  WAYPOINT_NUMBER_ABNORMAL: 1548,
  LAT_LONG_DATA_ABNORMAL: 1549,
  TURNING_INTERCEPT_ABNORMAL: 1550,
  WAYLINE_SEGMENT_MAX_SPEED_INVALID: 1551,
  WAYLINE_SEGMENT_TARGET_SPEED_INVALID: 1552,
  WAYPOINT_YAW_ANGLE_INVALID: 1553,
  BREAKPOINT_MISSION_ID_INVALID: 1555,
  BREAKPOINT_PROGRESS_INFO_INVALID: 1556,
  BREAKPOINT_STATE_INVALID: 1557,
  BREAKPOINT_WAYPOINT_INDEX_INVALID: 1558,
  BREAKPOINT_LAT_LONG_INVALID: 1559,
  BREAKPOINT_YAW_INVALID: 1560,
  BREAKPOINT_FLAG_INVALID: 1561,
  WAYLINE_GENERATION_FAILED: 1563,
  WAYLINE_EXECUTION_FAILED: 1564,
  WAYLINE_OBSTACLE_STOP: 1565,
  UNRECOGNIZED_ACTION_TYPE: 1588,
  ACTION_ID_NOT_UNIQUE: 1595,
  ACTION_ID_INVALID: 1598,
  ACTION_GROUP_COUNT_INVALID: 1602,
  ACTION_GROUP_RANGE_ERROR: 1603,
  BREAKPOINT_ACTION_INDEX_INVALID: 1606,
  BREAKPOINT_TRIGGER_RESULT_ABNORMAL: 1608,
  BREAKPOINT_DUPLICATE_ACTION_GROUP_ID: 1609,
  BREAKPOINT_DUPLICATE_ACTION_POSITIONS: 1610,
  BREAKPOINT_ACTION_POSITIONS_INVALID: 1611,
  BREAKPOINT_MISSING_ACTION_ID: 1612,
  BREAKPOINT_CANNOT_INTERRUPT_ACTION: 1613,
  BREAKPOINT_RESUME_FAILURE: 1614,
  UNRECOGNIZED_TRIGGER_TYPE: 1649,
  UNKNOWN_ISSUE_1: 65534,
  UNKNOWN_ISSUE_2: 65535
};

export const DjiCloudMissionWaylineBreakReasonText = {
  0: "No abnormalities",
  1: "Mission ID does not exist. The wayline mission has not been executed.",
  2: "Uncommon error, please contact technical support",
  4: "Error loading wayline file when requesting to start/resume the wayline mission, please try uploading the file again or contact technical support",
  5: "Failed to query breakpoint file when requesting breakpoint information. Failed to parse breakpoint type when requesting to resume the wayline mission",
  6: "Incorrect cmd parameter when requesting to start/end the wayline mission, incorrect protocol command in the request. Failed to parse breakpoint type when requesting to resume the wayline mission",
  7: "Timeout parsing the WPMZ file when requesting to start/resume the wayline mission, please retry",
  257: "Wayline has already started, cannot start again",
  258: "Unable to interrupt the wayline in this state, only allowed to pause the wayline in the executing state",
  259: "Wayline has not started, cannot end the wayline",
  261: "Flight mission conflict, unable to obtain control of the aircraft, not allowed to start the wayline during landing and return",
  262: "Unable to resume wayline in this state, only allowed when the wayline is paused",
  513: "Aircraft exceeded the maximum altitude limit",
  514: "Aircraft exceeded the maximum distance limit",
  516: "Aircraft triggered altitude limit",
  517: "Aircraft triggered obstacle sensing",
  518: "Poor RTK signal",
  519: "Approaching the boundary of Restricted Zone",
  521: "Exceeded the dock's GEO zone altitude limit",
  522: "Failed to request takeoff for the wayline",
  523: "Takeoff mission execution failed",
  524: "Failed to request wayline mission",
  526: "Failed to request wayline RTK fixing mission",
  527: "Wayline RTK fixing mission failed to run",
  769: "Weak GPS signal",
  770: "The remote controller gear is not in N position.",
  771: "Home point not refreshed",
  772: "Unable to start the mission due to low current battery level",
  773: "Wayline interrupted due to low battery causing return to home",
  775: "Disconnection between the remote controller and the aircraft",
  778: "Aircraft is on the ground with propellers spinning, not allowed to start the wayline",
  779: "During the real-time terrain follow, the camera state is abnormal (such as too bright, too dark and inconsistent brightness on both sides)",
  780: "Real-time terrain-following altitude set by the user is invalid (greater than 200m or less than 30m)",
  781: "Global map calculation error during real-time terrain follow",
  784: "Wayline interrupted due to strong winds causing return to home",
  1281: "User exit",
  1282: "User interruption",
  1283: "User triggered return to home",
  1539: "Incorrect start information (waypoint index or progress)",
  1540: "Using an unsupported coordinate system",
  1541: "Using an unsupported altitude mode",
  1542: "Using an unsupported transitional wayline mode",
  1543: "Using an unsupported yaw mode",
  1544: "Using an unsupported yaw direction reversal mode",
  1545: "Using an unsupported waypoint type",
  1546: "Coordinated turning type cannot be used for the start and end points",
  1547: "Wayline global speed exceeds a reasonable range",
  1548: "Waypoint number abnormal",
  1549: "Abnormal latitude and longitude data",
  1550: "Abnormal turning intercept",
  1551: "Maximum speed of wayline segment exceeds a reasonable range",
  1552: "Wayline segment target speed exceeds a reasonable range",
  1553: "Waypoint yaw angle exceeds a reasonable range",
  1555: "Input mission_id of resuming from breakpoint is wrong",
  1556: "Progress information of resuming from breakpoint input error",
  1557: "Mission state of resuming from breakpoint is abnormal",
  1558: "Waypoint index information of resuming from breakpoint input error",
  1559: "Incorrect latitude and longitude information for resuming from breakpoint",
  1560: "Yaw input error for waypoints during resuming from breakpoint",
  1561: "Incorrect flag setting for resuming from breakpoint",
  1563: "Wayline generation failed",
  1564: "Wayline execution failed",
  1565: "Emergency stop due to wayline obstacle sensing",
  1588: "Unrecognized action type",
  1595: "Action ID must be unique.",
  1598: "Action ID value cannot be 65535",
  1602: "Number of action groups exceeds a reasonable range",
  1603: "Error in action group effective range",
  1606: "Action index exceeds a reasonable range during resuming from breakpoint",
  1608: "Trigger running result of breakpoint information is abnormal",
  1609: "Action group ID information cannot be duplicated during resume from breakpoint",
  1610: "Action group positions cannot be repeated during resuming from breakpoint",
  1611: "Action group positions exceed a reasonable range during resuming from breakpoint",
  1612: "Action ID is not in the breakpoint information during resuming",
  1613: "The action state cannot be set to interrupt during the resuming flight from breakpoint",
  1614: "Resume failure due to incorrect breakpoint information",
  1634: "Unrecognized action type",
  1649: "Unrecognized trigger type",
  65534: "Unknown issue",
  65535: "Unknown issue"
};

export const MissionPendingRequestType = {
  REGISTER_ON_SERVER: 'register_on_server',
  QUERY_STATE: 'query_state',
  ONBOARD_UPLOAD: 'onboard_upload',
  ONBOARD_START: 'onboard_start',
  ONBOARD_PREPARATION: 'onboard_preparation',
  ONBOARD_ENTER_MISSION: 'onboard_enter_mission',
  ONBOARD_ENTER_OPERATION: 'onboard_enter_operation',
  ONBOARD_RESUME_MISSION: 'onboard_resume_mission',
  CLOUD_TAKEOFF_PREPARATION: 'cloud_takeoff_preparation',
  CLOUD_FLY_PREPARATION: 'cloud_fly_preparation',
  CLOUD_FLY_TO_POINT: 'cloud_fly_to_point',
  CLOUD_TAKEOFF_TO_POINT: 'cloud_takeoff_to_point',
  PAUSE: 'pause',
  RESUME: 'resume',
  STOP: 'stop',
  TERMINATE: 'terminate',
};

export const MissionPendingRequestStatus = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
};

export const DjiCloudRcLostAction = {
  HOVERING: 1,
  LANDING: 2,
  RETURN_HOME: 3,
};

export const DjiCloudRcLostActionText = {
  [DjiCloudRcLostAction.HOVERING]: 'Hovering',
  [DjiCloudRcLostAction.LANDING]: 'Landing',
  [DjiCloudRcLostAction.RETURN_HOME]: 'RTH',
};

export const WAYPOINT_MAX_ALTITUDE = 100;
export const WAYPOINT_MIN_ALTITUDE = 5;
export const WAYPOINT_MAX_SPEED = 10;
export const WAYPOINT_MIN_RTH_ALTITUDE = 20;