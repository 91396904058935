import { useEffect, useMemo } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { DeviceMapMarkerTypes, DeviceMapPathType } from "src/components/DeviceMap/DeviceMap";
import { addToPathCoordinates, setMarkers } from "src/components/DeviceMap/DeviceMapSlice";
import { deviceTypes } from "src/helper/constants";
import { DeviceOnlineStatus } from "src/helper/useDockList";
import { setDeviceInfo } from "../device/DeviceServiceSlice";
import { PersonMarkerTypes } from "src/components/DeviceMap/MapPersonMarker/MapPersonMarker";
import { setDeviceDrcState } from "../flight/FlightServiceSlice";

export const useDeviceMapMarkerCollector = ({ deviceService, missionService, userService }) => {
  const dispatch = useDispatch();
  const deviceTelemetries = useSelector((state) => state.deviceService.deviceTelemetries);
  const airsenseInfo = useSelector((state) => state.deviceService.deviceAirSense);

  const droneMarkers = useMemo(() => {
    const drones = deviceService.devices.filter((device) => 
      device.type === deviceTypes.DRONE && 
      device.onlineStatus === DeviceOnlineStatus.ONLINE && 
      deviceTelemetries[device.id]?.gps
    );

    return drones.map((drone) => {
      const deviceTelemetry = deviceTelemetries[drone.id];
      const heading = deviceTelemetry?.facing?.heading || 0;

      return ({
        type: DeviceMapMarkerTypes.DRONE,
          title: drone.name,
          lat: deviceTelemetry.gps?.lat,
          long: deviceTelemetry.gps?.long,
          data: {
            sourceId: drone.id,
            raw: drone,
            heading: heading,
          }
      });
    });
  }, [deviceService.devices, deviceTelemetries]);

  const dockMarkers = useMemo(() => {
    const docks = deviceService.devices.filter((device) => device.type === deviceTypes.DOCK && device.coordinates);

    return docks.flatMap((dock) => {
      const deviceTelemetry = deviceTelemetries[dock.id];
      const subDeviceTelemetries = deviceTelemetries[deviceTelemetry?.sub_device?.device_sn];
      const subDeviceInfo = deviceService.devices.find(device => device.serialNumber === deviceTelemetry?.sub_device?.device_sn);
      const isSubDeviceOutOfDock = deviceTelemetry?.drone_in_dock === 0 || subDeviceTelemetries?.mode_code !== 0;
      const isSubDeviceOnline = deviceTelemetry?.sub_device?.device_online_status;

      const dockMarker = ({
        type: DeviceMapMarkerTypes.DOCK,
        title: dock.name,
        lat: deviceTelemetry?.latitude,
        long: deviceTelemetry?.longitude,
        data: {
          sourceId: dock.id,
          ...(!!deviceTelemetry?.sub_device?.device_online_status ? {subSourceId: deviceTelemetry?.sub_device?.device_sn} : {}),
          isOnline: dock.onlineStatus === DeviceOnlineStatus.ONLINE,
          raw: dock,
        }
      });

      const subDeviceMarker = subDeviceTelemetries && isSubDeviceOnline ? ({
        type: DeviceMapMarkerTypes.DRONE,
        title: dock.name + ' (Drone)',
        lat: subDeviceTelemetries.latitude,
        long: subDeviceTelemetries.longitude,
        data: {
          sourceId: deviceTelemetry?.sub_device?.device_sn,
          parentSourceId: dock.id,
          heading: subDeviceTelemetries?.attitude_head,
          isOnline: isSubDeviceOnline,
          raw: {
            ...deviceTelemetry?.sub_device,
            ...subDeviceInfo
          },
        }
      }) : null;

      return [dockMarker, subDeviceMarker].filter(Boolean);
    });
  }, [deviceService.devices, deviceTelemetries]);

  const unitMarkers = useMemo(() => {
    const units = missionService.unitList?.filter(item => item.location?.lat);

    return units.map((unit) => ({
      type: DeviceMapMarkerTypes.OPERATION_UNIT,
      title: unit.name,
      lat: unit.location?.lat,
      long: unit.location?.long,
      data: {
        sourceId: unit.id,
        raw: unit,
      }
    }));
  }, [missionService.unitList]);

  const mobileMarkers = useMemo(() => {
    const mobiles = userService.mobileClients?.filter(item => item.location?.lat);

    return mobiles.map((mobile) => ({
      type: DeviceMapMarkerTypes.MOBILE_DEVICE,
      title: mobile.user?.displayName,
      lat: mobile.location?.lat,
      long: mobile.location?.long,
      data: {
        sourceId: mobile.id,
        raw: mobile,
        type: mobile.user?.role === 'rc' ? PersonMarkerTypes.RC.id : PersonMarkerTypes.VIEWER.id,
      }
    }));
  }, [userService.mobileClients]);

  const adsbMarkers = useMemo(() => {
    const warnings = Object.entries(airsenseInfo).flatMap(([deviceId, deviceAirsense]) => {
      return deviceAirsense.warnings.map(warning => ({
        type: DeviceMapMarkerTypes.ADSB,
        title: warning.icao,
        lat: warning.latitude,
        long: warning.longitude,
        data: {
          sourceId: deviceId,
          raw: warning,
        }
      }));
    });

    return warnings;
  }, [airsenseInfo]);

  const deviceMapMarkers = useMemo(() => {
    const markers = [...unitMarkers, ...dockMarkers, ...droneMarkers, ...mobileMarkers, ...adsbMarkers].filter((marker) => {
      return marker.lat > -90 && marker.lat < 90 && marker.long > -180 && marker.long < 180;
    });

    return markers;
  }, [unitMarkers, dockMarkers, droneMarkers, mobileMarkers, adsbMarkers]);

  return deviceMapMarkers;
}