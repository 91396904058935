import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDeviceAirSenseWarning } from "../device/DeviceServiceSlice";

export const useDeviceAdsbInfoCollector = () => {
  const dispatch = useDispatch();
  const deviceAirsense = useSelector((state) => state.deviceService.deviceAirSense);

  useEffect(() => {
    const allWarnings = Object.entries(deviceAirsense).flatMap(([deviceId, device]) => {
      return device.warnings.map(warning => ({ ...warning, deviceId }));
    });
    const noAdsbInfoWarnings = allWarnings.filter(warning => warning.adsb === undefined);
    const targetIcaoIds = noAdsbInfoWarnings.map(warning => warning.icao);

    noAdsbInfoWarnings.forEach(warning => {
      dispatch(updateDeviceAirSenseWarning({
        deviceId: warning.deviceId,
        icao: warning.icao,
        adsb: false  
      }));
    });

    const url = 'https://adsbexchange-com1.p.rapidapi.com/v2/hex/' + targetIcaoIds.join(',') + '/';
    const options = {
      method: 'GET',
      headers: {
        'x-rapidapi-key': 'ffd9000ae9msh5bc9df9f5a905cbp196f5ejsnfdc1e135dbf3',
        'x-rapidapi-host': 'adsbexchange-com1.p.rapidapi.com'
      }
    };

    if(targetIcaoIds.length) {
      fetch(url, options)
        .then(response => response.json())
        .then(data => {
          const allData = data.ac || [data];
          console.log('🛩️ ADSB data:', allData);

          allData.forEach(adsbInfo => {
            const {hex, ...rest} = adsbInfo;
            const warning = allWarnings.find(warning => warning.icao === hex);
            
            dispatch(updateDeviceAirSenseWarning({
              deviceId: warning.deviceId,
              icao: hex,
              adsb: rest
            }));
          });
        })
        .catch(error => console.error('Error:', error));
    }
  }, [deviceAirsense]);
};