import './OsdFloatBox.sass';
import classNames from 'classnames';
import React, { useState } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

function OsdFloatBox({ title, icon: Icon, counter, counterColor, collapsable, onClose, style, children }) {
  const [collapsed, setCollapsed] = useState(true);

  const headerTools = [
    ...(collapsable ? [{ title: 'Collapse', icon: collapsed ? MdKeyboardArrowUp : MdKeyboardArrowDown, onClick: () => setCollapsed(!collapsed) }] : []),
    ...(onClose ? [{ title: 'Close', icon: MdClose, onClick: onClose }] : [])
  ];

  return (
    <div className={classNames("osd-float-box", { "collapsed": collapsed })} style={style}>
      <div className="header">
        {Icon ? <Icon className="icon" /> : null}
        <div className="title">{title}</div>
        {counter ? <div className="counter" style={counterColor ? {background: counterColor} : {}}>{counter}</div> : null}
        <div className="tools">
          {headerTools.map((tool, index) => (
            <tool.icon key={index} onClick={tool.onClick} className="item" title={tool.title} />
          ))}
        </div>
      </div>
      <div className="content">
        {children}
      </div>
    </div>
  )
}

export default OsdFloatBox