import React from 'react'
import AdsbStageOsd from './AdsbStageOsd/AdsbStageOsd'
import { StageElement } from '../../OperationDashboard'
import { DockVideoSourceDevice } from '../DockCamera/DockCamera'

function OperationStageOverlay({ entity, activeDockVideoSource, stageSplitted = false }) {
  console.log(activeDockVideoSource, '📂📂📂📂')
  return (
    <>
      <AdsbStageOsd entity={entity} style={{
        top: stageSplitted ? activeDockVideoSource === DockVideoSourceDevice.SUB_DEVICE ? '115px' : '60px' : '12px', 
        left: stageSplitted ? '10px' : '60px'
      }} />
    </>
  )
}

export default OperationStageOverlay