import { useDispatch, useStore } from 'react-redux';
import { dockConnectionManager } from 'src/helper/HubConnectionManager';
import { addDeviceAirSenseWarning, removeDeviceAirSenseWarning, setDeviceInfo } from '../DeviceServiceSlice';
import { getDeviceConnectionGroups, getDeviceInfo, getDeviceTelemetries, getSubDeviceTelemetries } from '../common/deviceUtils';
import { useEffect, useRef } from 'react';
import { djiCloudCustomMethod, djiCloudMethod } from 'src/services/common/constants';
import { getMissionInfo } from 'src/services/mission/common/missionUtils';
import { safeToggleVideoRecord, getCameraMode, switchCameraMode } from '../common/deviceCaptureUtils';
import { DjiCloudDrcState, DjiDockDroneCameraMode, DjiDockDroneMode, DjiDockMode } from '../common/deviceConstants';
import useCloudLiveFlight from 'src/services/mission/common/useCloudLiveFlight';
import useCurrentUserId from 'src/helper/useCurrentUserId';
import { setMissionInfo } from 'src/services/mission/MissionServiceSlice';

function useDockDeviceObserver() {
  const messageHandlerIds = useRef([]);
  const permanentIntervalId = useRef(null);
  const store = useStore();
  const dispatch = useDispatch();
  const liveFlight = useCloudLiveFlight();
  const userId = useCurrentUserId();

  useEffect(() => {
    messageHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: 'dockDeviceState/*',
        name: ['state', 'events'],
        handler: (message, meta) => {
          const deviceId = meta?.fromUserId?.split('_')[0];
          const deviceInfo = getDeviceInfo(deviceId);
          const connection = getDeviceConnectionGroups(deviceId);
          const data = message.data;
          const method = message.method;
  
          if (data?.live_status) {
            const currStreamStatus = store.getState().deviceService.devices?.find(device => device.id === deviceId)?.streamStatus;
            const relatedMissionInfo = getMissionInfo(mission => mission.deviceId === deviceId);

            data.live_status.forEach((liveStatus) => {
              const updateTarget = liveStatus.video_id.includes(deviceInfo?.serialNumber) ? 'dock' : 'subDevice';
              
              if(currStreamStatus?.[updateTarget] !== liveStatus.error_status) {
                dispatch(setDeviceInfo({
                  deviceId,
                  data: {
                    streamStatus: {
                      ...currStreamStatus,
                      [updateTarget]: liveStatus.error_status
                    }
                  }
                }));
              }
            });
          }

          if(method === djiCloudMethod.event.HMS) {
            if(data?.list?.find(hms => hms.code === '0x1C001307')) {
              const missionInfo = getMissionInfo(mission => mission.deviceId === deviceId);

              setTimeout(() => {
                const telemetries = getSubDeviceTelemetries(deviceId);
                console.log('🎥 Recording available', {missionInfo, telemetries, userId});

                // mission auto recording
                // if(missionInfo?.missionDetails?.autoRecord && telemetries?.mode_code !== DjiDockDroneMode.STANDBY && missionInfo?.controllerId === userId) {
                //   safeToggleVideoRecord(deviceId, true);
                // }
              }, 5000);
            }
          }

          if(data?.rtcm_info) {
            const missionInfo = getMissionInfo(mission => mission.deviceId === deviceId);
            const deviceInfo = getDeviceInfo(deviceId);

            // Start stream recording
            if(missionInfo?.missionDetails?.autoRecord && missionInfo?.controllerId === userId) {
              // Disabled for now
              if(false && !missionInfo?.streamRecordStatus?.dock) {
                dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.send?.commands, {
                  method: djiCloudCustomMethod.live.START_DOCK_RECORDING,
                  gateway: deviceInfo?.serialNumber,
                  data: null
                });

                dispatch(setMissionInfo({
                  id: missionInfo.id,
                  streamRecordStatus: {
                    dock: true,
                    drone: missionInfo.streamRecordStatus?.drone || false
                  }
                }));
              }

              if(!missionInfo?.streamRecordStatus?.drone) {
                switchCameraMode(deviceId, DjiDockDroneCameraMode.CAPTURE);

                setTimeout(() => {
                  dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.send?.commands, {
                    method: djiCloudCustomMethod.live.START_DRONE_RECORDING,
                    gateway: deviceInfo?.serialNumber,
                    data: null
                  });
  
                  dispatch(setMissionInfo({
                    id: missionInfo.id,
                    streamRecordStatus: {
                      dock: missionInfo.streamRecordStatus?.dock || false,
                      drone: true
                    }
                  }));
                }, 500);
              }
            }
          }

          if(method === djiCloudMethod.event.TRACK) {
            console.log('📃 TRACK event', data);

            const missionInfo = getMissionInfo(mission => mission.deviceId === deviceId);
            const deviceInfo = getDeviceInfo(deviceId);
            const telemetries = getDeviceTelemetries(deviceId);

            if(data?.list?.find(item => item.type === 'flighttask_prepare')) {
              // DRC auto enabling
              if(telemetries?.drc_state === DjiCloudDrcState.DISABLED && missionInfo?.controllerId === userId) {
                liveFlight.sendStartDrcChannel(deviceId);
              }
            }

            if(data?.list?.find(item => item.type === 'flighttask_execute')) {
              if(missionInfo?.missionDetails?.autoRecord && missionInfo?.controllerId === userId) {
                const connection = getDeviceConnectionGroups(deviceId);

                // Disabled for now
                // dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.send?.commands, {
                //   method: djiCloudCustomMethod.live.STOP_DOCK_RECORDING,
                //   gateway: deviceInfo?.serialNumber,
                //   data: null
                // });

                dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.send?.commands, {
                  method: djiCloudCustomMethod.live.STOP_DRONE_RECORDING,
                  gateway: deviceInfo?.serialNumber,
                  data: null
                });

                dispatch(setMissionInfo({
                  id: missionInfo.id,
                  streamRecordStatus: {
                    dock: false,
                    drone: false
                  }
                }));
              }
            }
          }

          if(meta.groupName === 'state') {
            const fromSubDevice = meta.serialNumber !== deviceInfo?.serialNumber;

            if(data?.firmware_version && fromSubDevice) {
              console.log('⚙️ Drone Firmware', {
                serialNumber: meta.serialNumber, 
                firmware: data.firmware_version}
              );
            }
          }

          if(method === djiCloudMethod.airSense.AIRSENSE_WARNING) {
            dispatch(addDeviceAirSenseWarning({
              deviceId,
              data
            }));

            console.log('🚨 Airsense warning', data);
          }
        },
      },
    ], 'dock-device-observer');

    // check device info every 1 second
    permanentIntervalId.current = setInterval(() => {
      const airSenseWarnings = store.getState().deviceService.deviceAirSense;
      const now = new Date().getTime();

      Object.keys(airSenseWarnings).forEach(deviceId => {
        const filteredWarnings = airSenseWarnings[deviceId].warnings?.filter(warning => now - warning.timestamp > 10000);
        filteredWarnings?.forEach(warning => {
          dispatch(removeDeviceAirSenseWarning({
            deviceId,
            icao: warning.icao
          }));
        });
      });
    }, 1000);

    return () => {
      dockConnectionManager.unsubscribeGroupMessages(messageHandlerIds.current);
      clearInterval(permanentIntervalId.current);
    }
  }, []);

  return {};
}

export default useDockDeviceObserver