import { useEffect, useMemo } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { DeviceMapMarkerTypes, DeviceMapPathType } from "src/components/DeviceMap/DeviceMap";
import { addToPathCoordinates, setMarkers } from "src/components/DeviceMap/DeviceMapSlice";
import { deviceTypes } from "src/helper/constants";
import { DeviceOnlineStatus } from "src/helper/useDockList";
import { setDeviceInfo } from "../device/DeviceServiceSlice";
import { PersonMarkerTypes } from "src/components/DeviceMap/MapPersonMarker/MapPersonMarker";
import { setDeviceDrcState } from "../flight/FlightServiceSlice";
import { useDeviceMapMarkerCollector } from "./useDeviceMapMarkerCollector";
import { useDeviceAdsbInfoCollector } from "./useDeviceAdsbInfoCollector";

export const useCollectorService = ({ deviceService, missionService, userService }) => {
  useDeviceAdsbInfoCollector();
  const dispatch = useDispatch();
  const deviceTelemetries = useSelector((state) => state.deviceService.deviceTelemetries);
  const deviceMapMarkers = useDeviceMapMarkerCollector({ deviceService, missionService, userService });
  const store = useStore();

  // Update drone info with coordinates data
  useEffect(() => {
    const drones = deviceService.devices.filter((device) => 
      device.type === deviceTypes.DRONE && 
      device.onlineStatus === DeviceOnlineStatus.ONLINE && 
      deviceTelemetries[device.id]?.gps
    );

    drones.forEach(device => {
      if(deviceTelemetries[device.id]?.gps){
        dispatch(setDeviceInfo({ deviceId: device.id, data: { coordinates: deviceTelemetries[device.id].gps } }));
        dispatch(addToPathCoordinates({ 
          deviceId: device.id, 
          coords: [[deviceTelemetries[device.id].gps.long, deviceTelemetries[device.id].gps.lat]],
          type: DeviceMapPathType.DEVICE_FOOTMARK
        }))
      }
    });
    
  }, [deviceService.devices, deviceTelemetries]);

  // Update dock location with coordinates data
  useEffect(() => {
    const docks = deviceService.devices.filter((device) => 
      device.type === deviceTypes.DOCK && 
      device.onlineStatus === DeviceOnlineStatus.ONLINE && 
      deviceTelemetries[device.id]?.latitude &&
      deviceTelemetries[device.id]?.longitude
    );

    docks.forEach(device => {
      dispatch(setDeviceInfo({ deviceId: device.id, data: { coordinates: {
        lat: deviceTelemetries[device.id].latitude,
        long: deviceTelemetries[device.id].longitude
      }}}));
    });
  }, [deviceService.devices, deviceTelemetries]);

  useEffect(() => {
    const currMarkers = store.getState().deviceMap.markers;
    
    const keepMarkers = currMarkers?.filter(item => ![
      DeviceMapMarkerTypes.DRONE,
      DeviceMapMarkerTypes.OPERATION_UNIT,
      DeviceMapMarkerTypes.DOCK,
      DeviceMapMarkerTypes.MOBILE_DEVICE,
      DeviceMapMarkerTypes.ADSB,
    ].includes(item.type));

    dispatch(setMarkers([
      ...keepMarkers,
      ...deviceMapMarkers,
    ]));
  }, [deviceMapMarkers]);

  // Update DRC state for cloud devices
  useEffect(() => {
    const targetDevices = deviceService.devices.filter((device) => 
      device.type === deviceTypes.DOCK && 
      device.onlineStatus === DeviceOnlineStatus.ONLINE
    );

    const currDrcState = store.getState().flightService.drcState;

    targetDevices.forEach(device => {
      const newDeviceDrcState = deviceTelemetries[device.id]?.drc_state;

      if(newDeviceDrcState != undefined && currDrcState[device.id] !== newDeviceDrcState){
        dispatch(setDeviceDrcState({ deviceId: device.id, data: newDeviceDrcState }));
      }
    });
    
  }, [deviceService.devices, deviceTelemetries]);

  return true;
}