export const djiCloudMethod = {
  wayline: {
    DEVICE_EXIT_HOMING_NOTIFY: 'device_exit_homing_notify',
    FLIGHTTASK_PROGRESS: 'flighttask_progress',
    FLIGHTTASK_READY: 'flighttask_ready',
    RETURN_HOME_INFO: 'return_home_info',
    FLIGHTTASK_CREATE: 'flighttask_create',
    FLIGHTTASK_PREPARE: 'flighttask_prepare',
    FLIGHTTASK_EXECUTE: 'flighttask_execute',
    FLIGHTTASK_UNDO: 'flighttask_undo',
    FLIGHTTASK_PAUSE: 'flighttask_pause',
    FLIGHTTASK_RECOVERY: 'flighttask_recovery',
    FLIGHTTASK_RESOURCE_GET: 'flighttask_resource_get',
    RETURN_HOME: 'return_home',
    RETURN_HOME_CANCEL: 'return_home_cancel',
  },
  liveFlight: {
    FLIGHT_AUTHORITY_GRAB: 'flight_authority_grab',
    HEART_BEAT: 'heart_beat',
    DRONE_CONTROL: 'drone_control',
    TAKEOFF_TO_POINT: 'takeoff_to_point',
    TAKEOFF_TO_POINT_PROGRESS: 'takeoff_to_point_progress',
    FLY_TO_POINT: 'fly_to_point',
    FLY_TO_POINT_PROGRESS: 'fly_to_point_progress',
    FLY_TO_POINT_STOP: 'fly_to_point_stop',
    TRACK: 'track',
    CAMERA_SCREEN_DRAG: 'camera_screen_drag',
    GIMBAL_RESET: 'gimbal_reset',
    CAMERA_FOCAL_LENGTH_SET: 'camera_focal_length_set',
    CAMERA_MODE_SWITHCH: 'camera_mode_switch',
    CAMERA_PHOTO_TAKE: 'camera_photo_take',
    CAMERA_RECORDING_START: 'camera_recording_start',
    CAMERA_RECORDING_STOP: 'camera_recording_stop',
  },
  customFlightArea: {
    FLIGHT_AREAS_GET: 'flight_areas_get',
  },
  live: {
    LIVE_START_PUSH: 'live_start_push',
    LIVE_LENS_CHANGE: 'live_lens_change',
    LIVE_SET_QUALITY: 'live_set_quality',
  },
  debug: {
    DEVICE_REBOOT: 'device_reboot',
  },
  status: {
    UPDATE_TOPO: 'update_topo',
  },
  event: {
    HMS: 'hms',
    TRACK: 'track',
  },
  airSense: {
    AIRSENSE_WARNING: 'airsense_warning',
  }
}

export const djiCloudCustomMethod = {
  flight: {
    GRANT_SUB_DEVICE_PERMISSIONS: 'grant_sub_device_permissions',
  },
  live: {
    CREATE_LIVE_STREAMING: 'create_live_streaming',
    GET_LIVE_STREAMING: 'get_live_streaming',
    LIVE_STREAMING_RESPONSE: 'live_streaming_response',
    DOCK_VIDEO_RESPONSE: 'dock_video_response',
    DRONE_VIDEO_RESPONSE: 'drone_video_response',
    DOCK_VIDEO_PUBLISHED: 'publish_dock_video',
    DRONE_VIDEO_PUBLISHED: 'publish_drone_video',
    GET_DRONE_SHARED_VIDEO_LINK: 'get_drone_shared_video_link',
    GET_DOCK_SHARED_VIDEO_LINK: 'get_dock_shared_video_link',
    GET_DRONE_SHARED_VIDEO_LINK_RESPONSE: 'get_drone_shared_video_link_response',
    GET_DOCK_SHARED_VIDEO_LINK_RESPONSE: 'get_dock_shared_video_link_response',
    START_DOCK_RECORDING: 'start_dock_recording',
    STOP_DOCK_RECORDING: 'stop_dock_recording',
    START_DRONE_RECORDING: 'start_drone_recording',
    STOP_DRONE_RECORDING: 'stop_drone_recording',
    GET_DOCK_RECORDING_STATUS: 'get_dock_recording_status',
    GET_DRONE_RECORDING_STATUS: 'get_drone_recording_status',
  },
  mission: {
    MISSION_INIT: 'init_mission',
    MISSION_QUERY: 'get_mission_status',
    MISSION_TERMINATE: 'terminate_mission'
  },
  liveFlight: {
    START_DRC_CHANNEL :'start_drc_channel',
    STOP_DRC_CHANNEL :'stop_drc_channel',
    CONFIG_DRC_CHANNEL :'config_drc_channel',
    GET_DRC_CHANNEL_STATUS :'get_drc_channel_status',
    START_DRC_CHANNEL_RESPONSE :'start_drc_channel_response',
    STOP_DRC_CHANNEL_RESPONSE :'stop_drc_channel_response',
    CONFIG_DRC_CHANNEL_RESPONSE :'config_drc_channel_response',
    GET_DRC_CHANNEL_STATUS_RESPONSE :'get_drc_channel_status_response',
  }
}

export const djiWaylineStatus = {
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  IN_PROGRESS: 'in_progress',
  SUCCESS: 'ok',
  PARTITALY_DONE: 'partially_done',
  PAUSED: 'paused',
  REJECTED: 'rejected',
  SENT: 'sent',
  TIMEOUT: 'timeout',
}

export const djiWaylineStatusMessages = {
  "canceled":"Cancelled or terminated",
  "failed":"Failed",
  "in_progress":"Executing",
  "ok":"Execution successful",
  "paused":"Pause",
  "rejected":"Rejected",
  "sent":"Issued",
  "timeout":"Timeout",
}	

export const djiWaylineMissionState = {
  DISCONNECTED: 0,
  NOT_SUPPORTED: 1,
  PREPARATION: 2,
  UPLOADING: 3,
  TRIGGER_START: 4,
  ENTERING_WAYLINE: 5,
  EXECUTION: 6,
  INTERRUPTED: 7,
  RECOVERY: 8,
  COMPLETED: 9,
}

export const djiWaylineMissionStateMessages = [
  "Disconnected",
  "This waypoint is not supported",
  "Wayline preparation state. It can upload files and execute existing files",
  "Wayline file uploading",
  "Trigger start command. The aircraft triggers logic such as reading the wayline. The mission has not started yet and it's in a preparation state",
  "Entering the wayline, heading to the first waypoint",
  "Wayline execution",
  "Wayline interrupted. Triggered conditions are 1 for User actively pauses and 2 for Flight control abnormality",
  "Wayline recovery",
  "Wayline completed"
]

export const djiWaylineStep = {
  INITIAL_STATE: 0,
  PRE_FLIGHT_CHECK_WAYLINE: 1,
  PRE_FLIGHT_CHECK_DOCK_EXIT: 2,
  WAYLINE_EXECUTION: 3,
  PRE_RETURN_TO_HOME: 4,
  WAYLINE_EXECUTION_PREPARATION: 5,
  DOCK_WORKING_STATE: 6,
  STARTUP_CHECK: 7,
  WAITING_FOR_FLIGHT_SYSTEM: 8,
  WAITING_FOR_RTK: 9,
  CHECK_RTK_SOURCE: 10,
  WAITING_FOR_FLIGHT_CONTROL: 11,
  DOCK_NO_CONTROL: 12,
  GET_LATEST_KMZ_URL: 13,
  DOWNLOAD_KMZ: 14,
  KMZ_UPLOADING: 15,
  COLORING_CONFIGURATION: 16,
  AIRCRAFT_TAKEOFF_PARAMETER_SETTING: 17,
  AIRCRAFT_FLYTO_TAKEOFF_PARAMETER_SETTING: 18,
  HOME_POINT_SETTING: 19,
  TRIGGER_WAYLINE_EXECUTION: 20,
  WAYLINE_EXECUTING: 21,
  PRE_RETURN_TO_HOME_CHECK: 22,
  AIRCRAFT_LANDING_AT_DOCK: 23,
  CLOSE_COVER_AFTER_LANDING: 24,
  DOCK_EXIT_WORKING_MODE: 25,
  DOCK_ABNORMAL_RECOVERY: 26,
  DOCK_UPLOADS_FLIGHT_SYSTEM_LOGS: 27,
  CHECK_CAMERA_RECORDING_STATE: 28,
  GET_NUMBER_OF_MEDIA_FILES: 29,
  DOCK_ABNORMAL_RECOVERY_AFTER_TAKEOFF_COVER: 30,
  NOTIFY_MISSION_RESULT: 31,
  MISSION_EXECUTION_COMPLETE: 32,
  LOG_LIST_RETRIEVAL_AIRCRAFT_LIST: 33,
  LOG_LIST_RETRIEVAL_DOCK_LIST: 34,
  LOG_LIST_RETRIEVAL_UPLOAD_LOG_LIST_RESULT: 35,
  LOG_RETRIEVAL_AIRCRAFT_LOGS: 36,
  LOG_RETRIEVAL_DOCK_LOGS: 37,
  LOG_RETRIEVAL_COMPRESS_AIRCRAFT_LOGS: 38,
  LOG_RETRIEVAL_COMPRESS_DOCK_LOGS: 39,
  LOG_RETRIEVAL_UPLOAD_AIRCRAFT_LOGS: 40,
  LOG_RETRIEVAL_UPLOAD_DOCK_LOGS: 41,
  LOG_RETRIEVAL_NOTIFY_RESULT: 42,
}

export const djiWaylineStepMessages = [
  "Initial state",
  "Pre-flight check, whether the aircraft is executing a wayline",
  "Pre-flight check, whether the dock has exited working mode",
  "Pre-flight check, wayline execution in progress",
  "Pre-return-to-home check, in the process of returning to home",
  "Wayline execution enters the preparation state, waiting for the mission to be issued",
  "Dock enters working state",
  "Enter the startup check and cover preparation work",
  "Waiting for the flight system to be ready, push connection established",
  "Waiting for RTK source to listen for reported values",
  "Check if the RTK source is from the dock, if not, reset it",
  "Waiting for flight control notification",
  "Dock has no control, seize control of the aircraft",
  "Get the latest KMZ URL",
  "Download KMZ",
  "KMZ uploading",
  "Coloring configuration",
  "Aircraft takeoff parameter setting, alternate landing point setting, takeoff altitude setting, coloring setting",
  "Aircraft flyto takeoff parameter setting",
  "Home point setting",
  "Trigger wayline execution",
  "Wayline executing",
  "Enter pre-return-to-home check",
  "Aircraft landing at the dock",
  "Close the cover after landing",
  "Dock exits working mode",
  "Dock abnormal recovery",
  "Dock uploads flight system logs",
  "Check camera recording state",
  "Get the number of media files",
  "Abnormal recovery after dock takeoff cover",
  "Notify mission result",
  "Mission execution complete, configured in the profile, whether to actively pull logs",
  "Log list retrieval - Aircraft list",
  "Log list retrieval - Retrieve the list of docks",
  "Log list retrieval - Upload log list result",
  "Log retrieval - Retrieve aircraft logs",
  "Log retrieval - Retrieve dock logs",
  "Log retrieval - Compress aircraft logs",
  "Log retrieval - Compress dock logs",
  "Log retrieval - Upload aircraft logs",
  "Log retrieval - Upload dock logs",
  "Log retrieval - Notify the result",
  "Wait for service response after completion",
]

export const djiOneKeyReturnStatus = {
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  IN_PROGRESS: 'in_progress',
  SUCCESS: 'ok',
  PARTITALY_DONE: 'partially_done',
  PAUSED: 'paused',
  REJECTED: 'rejected',
  SENT: 'sent',
  TIMEOUT: 'timeout',
}

export const djiOneKeyReturnMessages = {
  "canceled":"Cancelled or terminated",
  "failed":"Failed",
  "in_progress":"Executing",
  "ok":"Execution successful",
  "paused":"Pause",
  "rejected":"Rejected",
  "sent":"Issued",
  "timeout":"Timeout",
}

export const djiBreakpointBreakReason = {
	"0":"No abnormalities",
	"1":"Mission ID does not exist. The wayline mission has not been executed.",
	"2":"Uncommon error, please contact technical support",
	"4":"Error loading wayline file when requesting to start/resume the wayline mission, please try uploading the file again or contact technical support",
	"5":"Failed to query breakpoint file when requesting breakpoint information. Failed to parse breakpoint type when requesting to resume the wayline mission",
	"6":"Incorrect cmd parameter when requesting to start/end the wayline mission, incorrect protocol command in the request. Failed to parse breakpoint type when requesting to resume the wayline mission",
	"7":"Timeout parsing the WPMZ file when requesting to start/resume the wayline mission, please retry",
	"257":"Wayline has already started, cannot start again",
	"258":"Unable to interrupt the wayline in this state, only allowed to pause the wayline in the executing state",
	"259":"Wayline has not started, cannot end the wayline",
	"261":"Flight mission conflict, unable to obtain control of the aircraft, not allowed to start the wayline during landing and return",
	"262":"Unable to resume wayline in this state, only allowed when the wayline is paused",
	"513":"Aircraft exceeded the maximum altitude limit",
	"514":"Aircraft exceeded the maximum distance limit",
	"516":"Aircraft triggered altitude limit",
	"517":"Aircraft triggered obstacle sensing",
	"518":"Poor RTK signal",
	"519":"Approaching the boundary of Restricted Zone",
	"521":"Exceeded the dock's GEO zone altitude limit",
	"522":"Failed to request takeoff for the wayline",
	"523":"Takeoff mission execution failed",
	"524":"Failed to request wayline mission",
	"526":"Failed to request wayline RTK fixing mission",
	"527":"Wayline RTK fixing mission failed to run",
	"528":"接近用户自定义飞行区边界",
	"769":"Weak GPS signal",
	"770":"Remote controller not in N mode, unable to start the task",
	"771":"Home point not refreshed",
	"772":"Unable to start the mission due to low current battery level",
	"773":"Wayline interrupted due to low battery causing return to home",
	"775":"Disconnection between the remote controller and the aircraft",
	"778":"Aircraft is on the ground with propellers spinning, not allowed to start the wayline",
	"779":"Abnormal visual status (for example, too bright, too dark, inconsistent brightness on both sides) during real-time terrain follow",
	"780":"Real-time terrain-following altitude set by the user is invalid (greater than 200m or less than 30m)",
	"781":"Global map calculation error during real-time terrain follow",
	"784":"Wayline interrupted due to strong winds causing return to home",
	"1281":"User exit",
	"1282":"User interruption",
	"1283":"User triggered return to home",
	"1539":"Incorrect start information (waypoint index or progress)",
	"1540":"Using an unsupported coordinate system",
	"1541":"Using an unsupported altitude mode",
	"1542":"Using an unsupported transitional wayline mode",
	"1543":"Using an unsupported yaw mode",
	"1544":"Using an unsupported yaw direction reversal mode",
	"1545":"Using an unsupported waypoint type",
	"1546":"Coordinated turning type cannot be used for the start and end points",
	"1547":"Wayline global speed exceeds a reasonable range",
	"1548":"Waypoint number abnormal",
	"1549":"Abnormal latitude and longitude data",
	"1550":"Abnormal turning intercept",
	"1551":"Maximum speed of wayline segment exceeds a reasonable range",
	"1552":"Wayline segment target speed exceeds a reasonable range",
	"1553":"Waypoint yaw angle exceeds a reasonable range",
	"1555":"Input mission_id of resuming from breakpoint is wrong",
	"1556":"Progress information of resuming from breakpoint input error",
	"1557":"Mission state of resuming from breakpoint is abnormal",
	"1558":"Wapoint index information of resuming from breakpoint input error",
	"1559":"Incorrect latitude and longitude information for resuming from breakpoint",
	"1560":"Yaw input error for waypoints during resuming from breakpoint",
	"1561":"Incorrect flag setting for resuming from breakpoint",
	"1563":"Wayline generation failed",
	"1564":"Wayline execution failed",
	"1565":"Emergency stop due to wayline obstacle sensing",
	"1588":"Unrecognized action type",
	"1595":"Action ID of same action group can not be the same",
	"1598":"Action ID value cannot be 65535",
	"1602":"Number of action groups exceeds a reasonable range",
	"1603":"Error in action group effective range",
	"1606":"Action index exceeds a reasonable range during resuming from breakpoint",
	"1608":"Trigger running result of breakpoint information is abnormal",
	"1609":"Action group ID information can not be duplicated during resume from breakpoint",
	"1610":"Action group positions cannot be repeated during resuming from breakpoint",
	"1611":"Action group positions exceed a reasonable range during resuming from breakpoint",
	"1612":"Action ID is not in the breakpoint information during resuming",
	"1613":"Cannot modify the action state to interrupt during resuming",
	"1614":"Resume failure due to incorrect breakpoint information",
	"1634":"Unrecognized action type",
	"1649":"Unrecognized trigger type",
	"65534":"Unknown error",
	"65535":"Unknown error"
}