export const adsbFieldInfo = {
  "msg": { title: "Message", description: "Indicates if there is an error; default is 'No error'." },
  "now": { title: "Current Time", description: "The time this file was generated, in milliseconds since Jan 1, 1970, 00:00:00 GMT (the Unix epoch)." },
  "total": { title: "Total Aircraft", description: "Total number of aircraft returned." },
  "ctime": { title: "Cache Time", description: "The time this file was cached, in seconds since Jan 1, 1970, 00:00:00 GMT (the Unix epoch)." },
  "ptime": { title: "Processing Time", description: "The server processing time this request required, in milliseconds." },
  "hex": { title: "ICAO Identifier", description: "The 24-bit ICAO identifier of the aircraft, represented as 6 hexadecimal digits." },
  "r": { title: "Registration", description: "Aircraft registration pulled from the database." },
  "t": { title: "Type", description: "Aircraft type pulled from the database." },
  "dbFlags": { title: "Database Flags", description: "Bitfield for certain database flags indicating attributes like military, interesting, PIA, or LADD." },
  "type": { title: "Message Type", description: "Type of underlying messages or best source of current data for this position or aircraft." },
  "flight": { title: "Callsign", description: "The flight name or aircraft registration, up to 8 characters." },
  "alt_baro": { title: "Barometric Altitude", description: "The aircraft's barometric altitude in feet as a number, or 'ground' as a string." },
  "alt_geom": { title: "Geometric Altitude", description: "Geometric (GNSS/INS) altitude in feet referenced to the WGS84 ellipsoid." },
  "gs": { title: "Ground Speed", description: "Ground speed in knots." },
  "ias": { title: "Indicated Air Speed", description: "Indicated air speed in knots." },
  "tas": { title: "True Air Speed", description: "True air speed in knots." },
  "mach": { title: "Mach Number", description: "Mach number." },
  "track": { title: "Track", description: "True track over ground in degrees (0-359)." },
  "track_rate": { title: "Track Rate", description: "Rate of change of track, in degrees per second." },
  "roll": { title: "Roll", description: "Roll in degrees; negative values indicate left roll." },
  "mag_heading": { title: "Magnetic Heading", description: "Heading in degrees clockwise from magnetic north." },
  "true_heading": { title: "True Heading", description: "Heading in degrees clockwise from true north." },
  "baro_rate": { title: "Barometric Rate", description: "Rate of change of barometric altitude, in feet per minute." },
  "geom_rate": { title: "Geometric Rate", description: "Rate of change of geometric altitude, in feet per minute." },
  "squawk": { title: "Squawk Code", description: "Transponder squawk code." },
  "emergency": { title: "Emergency Status", description: "Emergency status code." },
  "category": { title: "Emitter Category", description: "Emitter category code." },
  "nav_qnh": { title: "Navigation QNH", description: "QNH setting (altimeter pressure setting) in hPa." },
  "nav_altitude_mcp": { title: "MCP Altitude", description: "Selected altitude in the Mode Control Panel (MCP), in feet." },
  "nav_altitude_fms": { title: "FMS Altitude", description: "Selected altitude in the Flight Management System (FMS), in feet." },
  "nav_heading": { title: "Selected Heading", description: "Selected heading in degrees." },
  "nav_modes": { title: "Navigation Modes", description: "Active navigation modes." },
  "lat": { title: "Latitude", description: "Latitude in decimal degrees." },
  "lon": { title: "Longitude", description: "Longitude in decimal degrees." },
  "nic": { title: "Navigation Integrity Category", description: "Navigation Integrity Category." },
  "rc": { title: "Radius of Containment", description: "Radius of containment, in meters." },
  "seen_pos": { title: "Position Age", description: "Seconds since the last position update." },
  "version": { title: "Version", description: "Version of the ADS-B message protocol." },
  "nic_baro": { title: "NIC Baro", description: "Navigation Integrity Category for barometric altitude." },
  "nac_p": { title: "NACp", description: "Navigation Accuracy Category for position." },
  "nac_v": { title: "NACv", description: "Navigation Accuracy Category for velocity." },
  "sil": { title: "SIL", description: "Source Integrity Level." },
  "sil_type": { title: "SIL Type", description: "Indicates the type of SIL (per hour or per sample)." },
  "gva": { title: "GVA", description: "Geometric Vertical Accuracy." },
  "sda": { title: "SDA", description: "System Design Assurance." },
  "mlat": { title: "MLAT", description: "Indicates if the position was obtained via multilateration." },
  "tisb": { title: "TIS-B", description: "Indicates if the data was obtained via Traffic Information Service – Broadcast." },
  "messages": { title: "Message Count", description: "Total number of Mode S messages processed." },
  "seen": { title: "Message Age", description: "Seconds since the last message was received from this aircraft." },
  "rssi": { title: "RSSI", description: "Received Signal Strength Indicator of the last received message." }
};

export const airsenseFieldInfo = {
  "altitude": { title: "Altitude", description: "The altitude of the detected aircraft in meters." },
  "altitude_type": { title: "Altitude Type", description: "Indicates the source of the altitude data: 0 for barometric altitude, 1 for GNSS altitude." },
  "distance": { title: "Distance", description: "The horizontal distance from the DJI aircraft to the detected aircraft in meters." },
  "heading": { title: "Heading", description: "The heading of the detected aircraft in degrees, ranging from 0 to 359." },
  "icao": { title: "ICAO Address", description: "The unique ICAO identifier of the detected aircraft, represented as a 6-character hexadecimal string." },
  "latitude": { title: "Latitude", description: "The latitude of the detected aircraft in decimal degrees." },
  "longitude": { title: "Longitude", description: "The longitude of the detected aircraft in decimal degrees." },
  "relative_altitude": { title: "Relative Altitude", description: "The altitude difference between the detected aircraft and the DJI aircraft in meters." },
  "vert_trend": { title: "Vertical Trend", description: "Indicates the vertical movement trend of the detected aircraft: 0 for level, 1 for climbing, 2 for descending." },
  "warning_level": { title: "Warning Level", description: "The AirSense warning level: 0 for unknown, 1 for no alert, 2 for caution, 3 for warning." }
};

export const adsbCategoryProperties = {
  "A0": { "description": "Unknown category", "color": "#ffffff", "size": 1.0 }, // White
  "A1": { "description": "Light", "color": "#00FF00", "size": 0.8 }, // Green
  "A2": { "description": "Small", "color": "#32CD32", "size": 1.0 }, // LimeGreen
  "A3": { "description": "Large", "color": "#FFD700", "size": 1.2 }, // Gold
  "A4": { "description": "High vortex large", "color": "#FFFF00", "size": 1.3 }, // Yellow
  "A5": { "description": "Heavy", "color": "#A020F0", "size": 1.4 }, // Purple
  "A6": { "description": "High performance", "color": "#FF0000", "size": 1.2 }, // Red
  "A7": { "description": "Rotorcraft", "color": "#8A2BE2", "size": 1.0 }, // BlueViolet
  "B0": { "description": "Unknown category", "color": "#ffffff", "size": 1.0 }, // White
  "B1": { "description": "Glider / sailplane", "color": "#87CEEB", "size": 0.8 }, // SkyBlue
  "B2": { "description": "Lighter-than-air", "color": "#ADD8E6", "size": 0.8 }, // LightBlue
  "B3": { "description": "Parachutist / skydiver", "color": "#4682B4", "size": 0.6 }, // SteelBlue
  "B4": { "description": "Ultralight / hang-glider / paraglider", "color": "#00CED1", "size": 0.6 }, // DarkTurquoise
  "B5": { "description": "Reserved", "color": "#ffffff", "size": 1.0 }, // White
  "B6": { "description": "Unmanned aerial", "color": "#000000", "size": 0.5 }, // Black
  "B7": { "description": "Space / trans-atmospheric", "color": "#FFD700", "size": 1.5 }, // Gold
  "C0": { "description": "Unknown category", "color": "#ffffff", "size": 1.0 }, // White
  "C1": { "description": "Surface emergency vehicle", "color": "#FF0000", "size": 1.0 }, // Red
  "C2": { "description": "Surface service vehicle", "color": "#FFFF00", "size": 1.0 }, // Yellow
  "C3": { "description": "Point obstacle", "color": "#A52A2A", "size": 0.8 }, // Brown
  "C4": { "description": "Cluster obstacle", "color": "#A52A2A", "size": 1.0 }, // Brown
  "C5": { "description": "Line obstacle", "color": "#A52A2A", "size": 1.2 }, // Brown
  "C6": { "description": "Reserved", "color": "#ffffff", "size": 1.0 }, // White
  "C7": { "description": "Reserved", "color": "#ffffff", "size": 1.0 }  // White
};

export const airsenseVerticalTrend = {
  LEVEL: 0,
  CLIMBING: 1,
  DESCENDING: 2
};

export const airsenseVerticalTrendText = {
  0: "Level",
  1: "Ascending",
  2: "Descending"
};

export const airsenseVerticalTrendTextShort = {
  0: "LVL",
  1: "ASC",
  2: "DESC"
};

export const AirsenseVerticalLevel = {
  LEVEL: 0,
  CLIMBING: 1,
  DESCENDING: 2
};

export const airsenseWarningLevelText = {
  0: "No Danger",
  1: "Caution",
  2: "Warning",
  3: "Danger",
  4: "Emergency"
};

export const airsenseWarningLevelColor = {
  0: "#3185f3",
  1: "#FFFF00",
  2: "#FFA500",
  3: "#FF0000",
  4: "#8B0000"
};