import { MdOutlineAirplanemodeActive } from 'react-icons/md';
import './MapAdsbMarker.sass';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Popover } from 'src/hci/common/Popover/Popover';
import { adsbCategoryProperties, adsbFieldInfo, airsenseFieldInfo, airsenseVerticalTrendText } from 'src/services/device/assets/airsenseConstants';

function MapAdsbMarker({title, data, className}) {
  const {raw, sourceId} = data;
  const [animate, setAnimate] = useState(true);
  const [lastHeading, setLastHeading] = useState(raw?.heading || 0);
  const [showDetails, setShowDetails] = useState(false);

  const dataDetailsToShow = ['icao', 'r', 't', 'flight', 'altitude', 'distance', 'heading', 'relative_altitude', 'vert_trend', 'category', 'latitude', 'longitude'];
  const adsbCategoryProps = adsbCategoryProperties[raw?.adsb?.category] || {
    size: 1,
    color: 'yellow',
  };

  const prepareDataFields = () => {
    return dataDetailsToShow.map(key => {
      const field = airsenseFieldInfo[key] || adsbFieldInfo[key];

      let title = field?.title || key;
      let value = raw[key] || raw.adsb?.[key];
      let description = field?.description || key;

      switch(key) {
        case 'vert_trend':
          value = airsenseVerticalTrendText[value] || value;
          break;

        case 'category':
          value = adsbCategoryProperties[value]?.description || value;
          break;
      }

      return {
        key,
        title,
        description,
        value
      };
    });
  };

  useEffect(() => {
    const heading = raw?.heading || 0;
    setLastHeading(curr => {
      setAnimate(Math.abs(heading - curr) < 270);
      return heading;
    });
  }, [raw]);

  return (
    <div className={classNames("map-adsb-marker", className)} title={title}>
      <Popover 
        isOpen={showDetails} 
        onClose={() => setShowDetails(false)}
        content={
          <div className="details">
            {prepareDataFields()?.map(field => (
              <div key={field.key} title={field.description}>
                <span>{field.title}</span>: {field.value}
              </div>
            ))}
          </div>
        }
      >
        <div 
          className="icon"
          style={{ 
            transform: `rotate(${lastHeading}deg)`,
            fontSize: `${adsbCategoryProps.size}em`,
            transition: animate ? 'all 0.3s' : 'none',
            color: adsbCategoryProps.color,
          }}
        >
          <MdOutlineAirplanemodeActive
            onClick={() => setShowDetails(prev => !prev)}
          />
        </div>
      </Popover>
    </div>
  )
}

export default MapAdsbMarker