import { useDispatch, useSelector } from 'react-redux';
import './OperationDashboard.sass';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SidePanel from 'src/hci/organism/SidePanel/SidePanel';
import Loading from 'src/ui/Loading/Loading';
import { MdOutlineClose } from 'react-icons/md';
import MissionFlightDashboard from './common/MissionFlightDashboard/MissionFlightDashboard';
import { activeMissionTrackingId, setSelectedEntity } from '../ExploreAppSlice';
import MapViewer from './common/MapViewer/MapViewer';
import { dockConnectionManager, mobileConnectionManager, onboardConnectionManager } from 'src/helper/HubConnectionManager';
import DeviceMap from 'src/components/DeviceMap/DeviceMap';
import WaypointCommandPanel from './common/WaypointCommandPanel/WaypoointCommandPanel';
import { setRunMissionRequest } from 'src/services/mission/MissionServiceSlice';
import { deviceTypes, djiWaylineOutOfControlActions, djiWaylineTaskTypes } from 'src/helper/constants';
import MissionLogViewer from 'src/services/mission/MissionLogViewer/MissionLogViewer';
import { generateId } from 'src/helper/utils';
import Button from 'src/hci/common/Button/Button';
import EntityList, { EntityType } from '../EntityList/EntityList';
import EntityDetails from './common/EntityDetails/EntityDetails';
import DockCamera from './common/DockCamera/DockCamera';
import { TbArrowBack, TbCross, TbHandMove, TbLocationBolt, TbRouteSquare, TbX } from 'react-icons/tb';
import DroneCamera from './common/DroneCamera/DroneCamera';
import MissionInfoPanel from './common/MissionInfoPanel/MissionInfoPanel';
import useMissionInfo from 'src/services/mission/common/useMissionInfo';
import useMissionController from 'src/services/mission/common/useMissionController';
import { MissionStatus, MissionType, NotActiveMissionStatusSet } from 'src/services/mission/common/missionConstants';
import useDeviceInfo from 'src/services/device/common/useDeviceInfo';
import { DeviceOnlineStatus } from 'src/helper/useOnboardDeviceList';
import { TbHomeUp } from 'react-icons/tb';
import useDeviceTelemetries from 'src/services/device/common/useDeviceTelemetries';
import useDialog from 'src/helper/useDialog';
import useDeviceConnection from 'src/services/device/common/useDeviceConnection';
import MobileCamera from './common/MobileCamera/MobileCamera';
import DebugPanel from './common/DebugPanel/DebugPanel';
import useSubDevice from 'src/services/device/common/useSubDevice';
import OperationTaskbar from './common/OperationTaskbar/OperationTaskbar';
import { DjiCloudDrcState, DjiDockDroneMode } from 'src/services/device/common/deviceConstants';
import DockStage from './common/DockStage/DockStage';
import Tabs from 'src/hci/common/Tabs/Tabs';
import DeviceOnboarding from '../../DeviceApp/OnboardDeviceApp/DeviceOnboarding/DeviceOnboarding';
import AddUnit from '../../SecurityApp/AddUnit/AddUnit';
import MessageArea from 'src/hci/organism/MessageArea/MessageArea';
import { GiMagnifyingGlass } from 'react-icons/gi';
import { RiMenuSearchLine } from 'react-icons/ri';
import { FaListUl } from 'react-icons/fa6';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { setLastSelectedMarker } from 'src/components/DeviceMap/DeviceMapSlice';
import { FiInfo } from 'react-icons/fi';
import DeviceOptionsPanel from './common/DeviceOptionsPanel/DeviceOptionsPanel';
import OperationStageOverlay from './common/OperationStageOverlay/OperationStageOverlay';

export const StageElement = {
  MAP: 'map',
  DOCK: 'dock',
  DOCK_DRONE: 'dock_drone',
  DRONE: 'drone_controller',
  MOBILE_DEVICE: 'mobile_device'
};

const SidebarTab = {
  ENTITIES: 'entities',
  DETAILS: 'details'
}

function OperationDashboard() {
  const entity = useSelector(state => state.exploreApp.selectedEntity);
  const dispatch = useDispatch();
  const dialog = useDialog();
  const confirmLandingDialogActive = useRef();
  const drcActionTimeoutRef = useRef();
  const prevSelectedEntity = useRef();
  const deviceInfo = useDeviceInfo(entity?.id);
  const telemetries = useDeviceTelemetries(entity?.id);
  const { telemetries: subDeviceTelemetries } = useSubDevice(entity?.id);
  const connection = useDeviceConnection(entity?.id);
  const entityOnlineStatus = deviceInfo?.onlineStatus;
  const [activeStageElement, setActiveStageElement] = useState(StageElement.MAP);
  const issueMissionResult = useSelector(state => state.missionService.issueMissionResult);
  const executeMissionResult = useSelector(state => state.missionService.executeMissionResult);
  const missionController = useMissionController(item => item.deviceId === entity?.id, entity?.id);
  const [showDfrForm, setshowDfrFormForm] = useState(false);
  const [showMissionObserver, setShowMissionObserver] = useState(false);
  const [drcBusy, setDrcBusy] = useState(false);
  const [showDroneOnboarding, setShowDroneOnboarding] = useState(false);
  const [showAddUnitPanel, setShowAddUnitPanel] = useState(false);
  const [sidebarCurrTab, setSidebarCurrTab] = useState(SidebarTab.ENTITIES);
  const [entityStageMinimized, setEntityStageMinimized] = useState(false);
  const [activeDockVideoSource, setActiveDockVideoSource] = useState();

  const entityName = entity?.name || deviceInfo?.name || deviceInfo?.title || entity?.user?.displayName;

  const forceLandingPending = useMemo(() => {
    const currHeight = telemetries?.altitudeInfo?.asl - telemetries?.gps?.homepoint?.alt;

    if(currHeight <= 0) return false;

    return (
      telemetries?.flightControl?.displayMode == 12 &&
      (currHeight - 0.7 < 0.001)
    );
  }, [telemetries]);

  const dfrCapable = (deviceInfo.type === deviceTypes.DRONE || deviceInfo.type === deviceTypes.DOCK) && 
  entityOnlineStatus === DeviceOnlineStatus.ONLINE && 
  !missionController.details?.status;

  const manualCapable = (
    (deviceInfo?.type === deviceTypes.DRONE && entityOnlineStatus === DeviceOnlineStatus.ONLINE) || 
    (
      deviceInfo?.type === deviceTypes.DOCK && 
      [DjiDockDroneMode.MANUAL_FLIGHT, DjiDockDroneMode.VIRTUAL_STICK_STATE].includes(subDeviceTelemetries?.mode_code)
    )
  );

  const rthAvailable = telemetries?.flightControl?.flightStatus === 2 || 
  (entity?.type === EntityType.DOCK && ![undefined, 0, 14].includes(subDeviceTelemetries?.mode_code));

  const swapStageElement = (element) => {
    setActiveStageElement(element);
  }

  const onEntityStageMinimize = (state) => {
    setEntityStageMinimized(state);
  }

  const handleDfrButtonClick = () => {
    setshowDfrFormForm(true);
  }

  const handleManualButtonClick = () => {
    if(entity.entityType === EntityType.DOCK) {
      if(telemetries?.drc_state === DjiCloudDrcState.DISABLED) {
        missionController.liveFlight.sendFlightAuthorityGrab(deviceInfo?.id, data => {
          if(data.result === 0) {
            missionController.liveFlight.sendStartDrcChannel(deviceInfo?.id, {}, data => {
              if(!data?.success) {
                dialog.fire({
                  title: <b>Manual Control</b>,
                  text: "Failed to start manual control",
                  icon: 'error',
                  showConfirmButton: true,
                });
    
                setDrcBusy(false);
              }
            });
          } else {
            dialog.fire({
              title: <b>Manual Control</b>,
              text: "Failed to grab flight authority. " + data.result_message,
              icon: 'error',
              showConfirmButton: true,
            });
          }
        });
      } 
      else if(telemetries?.drc_state === DjiCloudDrcState.ENABLED) {
        missionController.liveFlight.sendStopDrcChannel(deviceInfo?.id);
      }

      clearTimeout(drcActionTimeoutRef.current);
      drcActionTimeoutRef.current = setTimeout(() => {
        setDrcBusy(false);
      }, 20000);

      setDrcBusy(true);
    }
    else {
      swapStageElement(StageElement.DRONE);
    }
  }

  const handleMissionPanelClose = () => {
    setShowMissionObserver(false);
  }

  const handleOnCloseClick = () => {
    dispatch(setLastSelectedMarker(null));
    dispatch(setSelectedEntity(null));
  }

  const handleReturnHomeButtonClick = useCallback(() => {
    if(entity.entityType === EntityType.DOCK) {
      if(missionController.details?.status === MissionStatus.RETURNING)
        missionController.cancelRth();
      else {
        console.log('return')
        missionController.rth();
      }
    }
    else {
      telemetries?.flightControl?.displayMode == 15 ? missionController.cancelRth() : telemetries?.flightControl?.displayMode == 12 ? missionController.cancelLanding() : missionController.rth()
    }
  }, [entity, missionController]);

  useEffect(() => {
    if(forceLandingPending && !confirmLandingDialogActive.current) {
      dialog.fire({
        title: <b>Auto Landing</b>,
        text: "Do you want to finalize landing?",
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Confirm Landing',
      }).then(result => {
        if (result.isConfirmed) {
          onboardConnectionManager?.sendToGroup(entity?.id, connection?.group?.send?.commandChannel, {
            flightControl: {
              actionId: 6,
            }
          });
        }

        setTimeout(() => confirmLandingDialogActive.current = false, 2000);
      });

      confirmLandingDialogActive.current = true;
    } else if(confirmLandingDialogActive.current) {
      dialog.close();
      confirmLandingDialogActive.current = false;
    }
  }, [forceLandingPending]);

  useEffect(() => {
    if(missionController?.details?.status) {
      setShowMissionObserver(true);
    }
  }, [missionController])

  useEffect(() => {
    if(issueMissionResult) {
      console.log('📦 MISSION ISSUE RESULT', issueMissionResult);
    }
  }, [issueMissionResult]);

  useEffect(() => {
    if(executeMissionResult) {
      console.log('📦 MISSION EXECUTE RESULT', executeMissionResult)
    }
  }, [executeMissionResult]);

  useEffect(() => {
    setActiveStageElement({
      [EntityType.DOCK]: StageElement.DOCK,
      [EntityType.DRONE]: StageElement.DRONE,
      [EntityType.MOBILE_DEVICE]: StageElement.MOBILE_DEVICE,
    }[entity?.entityType] || null);

    if(!entity) setSidebarCurrTab(SidebarTab.ENTITIES);
    else if(!prevSelectedEntity.current || prevSelectedEntity.current?.id !== entity.id) {
      setSidebarCurrTab(SidebarTab.DETAILS);
    }

    setshowDfrFormForm(false);
    prevSelectedEntity.current = entity;
  }, [entity]);

  useEffect(() => {
    if(deviceInfo?.type !== deviceTypes.DRONE && deviceInfo?.type !== deviceTypes.DOCK) 
      return;

    if(deviceInfo.onlineStatus !== DeviceOnlineStatus.ONLINE)
      handleOnCloseClick();
  }, [deviceInfo]);

  useEffect(() => {
    setDrcBusy(false);
  }, [telemetries?.drc_state]);

  useEffect(() => {
    return () => {
      clearTimeout(drcActionTimeoutRef.current);
    }
  }, []);

  return (
    <div className="operation-dashboard">
      <SidePanel className={'entity-panel'} smallCollapseButton emptyHeader>
        <Tabs.Root value={sidebarCurrTab} onValueChange={setSidebarCurrTab} style={{ height: '100%' }}>
          <Tabs.List size="2" className="entities-tab-list">
            <Tabs.Trigger value={SidebarTab.ENTITIES}>
              <FaListUl className="icon" /> 
              <span>Entities</span>
            </Tabs.Trigger>
            <Tabs.Trigger value={SidebarTab.DETAILS}>
              <FiInfo className="icon" />
              <span className="entity-details-tab-title">{entityName || 'Details'}</span>
            </Tabs.Trigger>
          </Tabs.List>
          <div style={{ flexGrow: '1' }}>
            <Tabs.Content value={SidebarTab.ENTITIES}>
              <EntityList
                onAddDroneClick={() => setShowDroneOnboarding(true)} 
                onAddUnitClick={() => setShowAddUnitPanel(true)} 
              />
            </Tabs.Content>
            <Tabs.Content value={SidebarTab.DETAILS} style={{ position: 'relative', height: '100%' }}>
              {!!entity ? (
                <div className="side-panels-container">
                  <EntityDetails entity={entity} onClose={handleOnCloseClick} missionController={missionController} />
                  {(entity?.entityType === EntityType.DRONE || entity?.entityType === EntityType.DOCK) && (
                    <div className="entity-actions">
                      {deviceInfo.type !== deviceTypes.DRONE && (
                        <>
                          <Button 
                            onClick={handleDfrButtonClick} 
                            color="red"
                            size="1"
                            disabled={!dfrCapable} 
                            style={{flexShrink: 1}}
                          >
                            <TbLocationBolt className="icon" /> DFR
                          </Button>
                          <Button 
                            onClick={handleManualButtonClick} 
                            disabled={!manualCapable} 
                            size="1" 
                            loading={entity?.entityType === EntityType.DOCK && drcBusy}
                            color={entity?.entityType === EntityType.DOCK && telemetries?.drc_state === 2}
                          >
                            { entity?.entityType === EntityType.DOCK && telemetries?.drc_state === 2 ?
                              <><TbX  className="icon" /> CTRL</> :
                              <><TbHandMove className="icon" /> CTRL</>
                            }
                          </Button>
                        </>
                      )}
                      {/* <Button disabled><TbRouteSquare className="icon" /> Missions</Button> */}
                      <Button onClick={handleReturnHomeButtonClick} disabled={!rthAvailable} size="1" style={{flexGrow: '2'}}>
                        { telemetries?.flightControl?.displayMode == 15 || missionController.details?.status === MissionStatus.RETURNING ? 
                          <><TbX className="icon" /> CANCEL RTH</> :
                          telemetries?.flightControl?.displayMode == 12 ?
                          <><TbX className="icon" /> STOP LANDING</> :
                          <><TbArrowBack className="icon" /> RETURN HOME</>
                        }
                      </Button>
                    </div>
                  )}
                  {showMissionObserver && deviceInfo?.id && (missionController?.details?.deviceId || missionController?.details?.dockId) === deviceInfo?.id && (
                    <MissionInfoPanel deviceId={entity?.id} onClose={handleMissionPanelClose} controller={missionController} />
                  )}
                  {(showDfrForm || deviceInfo.type === deviceTypes.DRONE || missionController?.details?.missionType === MissionType.DFR) && (!missionController?.details?.status || NotActiveMissionStatusSet.includes(missionController.details?.status)) && (
                    <WaypointCommandPanel deviceId={entity?.id} onCancel={() => setshowDfrFormForm(false)} controller={missionController} />
                  )}
                  {(entity?.entityType === EntityType.DRONE || entity?.entityType === EntityType.DOCK) && (
                    <DeviceOptionsPanel entity={entity} collapsed={true} missionController={missionController} />
                  )}
                  { entity?.entityType === EntityType.DOCK && (
                    <DebugPanel entity={entity} collapsed={true} missionController={missionController} />
                  )}
                </div>
              ) : (
                <MessageArea
                  title="No Item Selected"
                  description="Please select an entity to view details"
                  icon={RiMenuSearchLine}
                />
              )}
            </Tabs.Content>
          </div>
        </Tabs.Root>
      </SidePanel>
      <div className="main-layout">
        <div className="stage-container">
          {
            {
              [StageElement.DOCK]: <DockStage key={"dock-stage-" + entity?.id} elementType={activeStageElement} entity={entity} missionController={missionController} onMinimize={onEntityStageMinimize} onVideoSourceSwitch={setActiveDockVideoSource} />,
              [StageElement.DOCK_DRONE]: <DockStage key={"dock-stage-" + entity?.id} elementType={activeStageElement} entity={entity} missionController={missionController} onMinimize={onEntityStageMinimize} onVideoSourceSwitch={setActiveDockVideoSource} />,
              [StageElement.MOBILE_DEVICE]: <MobileCamera key={"mobile-stage-"  + entity?.id} deviceId={entity?.id} expanded={true} />,
              [StageElement.DRONE]: <MissionFlightDashboard key={"drone-stage-" + entity?.id} deviceId={entity?.id} />,
            }[activeStageElement]
          }
          <DeviceMap key="map-stage" />
        </div>
        <OperationTaskbar />
        <OperationStageOverlay entity={entity} activeDockVideoSource={activeDockVideoSource} stageSplitted={!!activeStageElement && !entityStageMinimized} />
      </div>

      {showDroneOnboarding && (
        <DeviceOnboarding
          onCloseByUser={() => setShowDroneOnboarding(false)}
        />
      )}

      {showAddUnitPanel && (
        <AddUnit onCancel={() => setShowAddUnitPanel(false)} onSuccess={() => setShowAddUnitPanel(false)} />
      )}
    </div>
  )
}

export default OperationDashboard