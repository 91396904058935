import './CaptureTools.sass'
import { BsFillRecordFill, BsFillStopCircleFill, BsRecord } from 'react-icons/bs';
import { HiOutlineCamera, HiOutlineVideoCamera } from 'react-icons/hi';
import { DjiDockDroneCameraMode, DjiDockDroneRecordingState } from 'src/services/device/common/deviceConstants';
import useDeviceCapture from 'src/services/device/common/useDeviceCapture';

function CaptureTools({deviceId}) {
  const { 
    cameraMode, 
    recordingState, 
    recordingSeconds, 
    switchCameraMode, 
    captureImage, 
    safeToggleVideoRecord
  } = useDeviceCapture(deviceId);

  return (
    <div className="capture-tools">
      <div className="camera-mode" onClick={switchCameraMode} style={{ display: 'none' }}>
        {cameraMode === DjiDockDroneCameraMode.CAPTURE ? (
          <HiOutlineCamera tiitle="Switch to recording mode" />
        ) : (
          <HiOutlineVideoCamera title="Switch to photo mode" />
        )}
      </div>
      <div className="capture-button">
        {cameraMode === DjiDockDroneCameraMode.RECORDING ? 
          recordingState === DjiDockDroneRecordingState.RECORDING ? (
            <BsFillStopCircleFill onClick={() => safeToggleVideoRecord()} color="red" title="Stop Recording" />
          ) : (
            <BsFillRecordFill onClick={() => safeToggleVideoRecord()} color="red" title="Start Recording" />
          )
        : (
          <BsRecord onClick={captureImage} title="Take Photo" />
        )}
      </div>
      {recordingSeconds ? (
        <div className="capture-seconds">
          <span>
            {Math.floor(recordingSeconds / 60).toString().padStart(2, '0')}:
            {(recordingSeconds % 60).toString().padStart(2, '0')}
          </span>
        </div>
      ) : null}
    </div>
  )
}

export default CaptureTools