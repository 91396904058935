import { useState, useRef, useEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { v4 as uuidv4 } from "uuid";
import { v5 as uuidv5 } from "uuid";
import store from "src/redux/configureStore";
import { toast } from "react-toastify";
import NotificationItem, { NotificationType } from "src/components/Notification/NotificationItem/NotificationItem";
import { footToMeterRatio, meterToFootRatio, meterToMileRatio, mileToMeterRatio } from "./constants";

export const isNumber = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
};

export const isAlphaNumeric = (evt) => {
  const value = evt.target.value;
  const regex = "^[A-Za-z_ -]+[0-9]*$";
  return !value.match(regex) || value.length < 3;
};

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => void (ref.current = value), [value]);
  return ref.current;
}

export function useMeasure() {
  const ref = useRef();
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const [ro] = useState(
    () => new ResizeObserver(([entry]) => set(entry.contentRect))
  );
  useEffect(() => {
    if (ref.current) ro.observe(ref.current);
    return () => ro.disconnect();
  }, []);
  return [{ ref }, bounds];
}

export const mobileCheck = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)|plucker|pocket|psp|series(4|6)0|symbian|treo|up.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt(|)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const groupBy = (xs, key) => {
  if (xs !== undefined)
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  else return [];
};

export const toColor = (str) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return intToRGB(hash);
};
function intToRGB(i) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();
  return "00000".substring(0, 6 - c.length) + c;
}

export const isJson = (text) => {
  return /^[\],:{}\s]*$/.test(
    text
      .replace(/\\["\\\/bfnrtu]/g, "@")
      .replace(
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
        "]"
      )
      .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
  );
};

export const isGuid = (guid) => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(
    guid
  );
};

export const FormatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const toLocalTime = (utcSeconds) => {
  return new Date(utcSeconds);
};

export const getRandomClientId = () => {
  return Math.random().toString(36).substring(2).toUpperCase();
};

export const round = (number, precision) => {
  return (
    Math.round((number + Number.EPSILON) * Math.pow(10, precision)) /
    Math.pow(10, precision)
  );
};

export const random = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const meterToFeet = (meters) => {
  return meters * meterToFootRatio;
};

export const meterToMile = (meters) => {
  return meters * meterToMileRatio;
}

export const feetToMeter = (meters) => {
  return meters * footToMeterRatio;
};

export const mileToMeter = (meters) => {
  return meters * mileToMeterRatio;
}

export const generateId = (name) => {
  const MY_NAMESPACE = "1b671a64-40d5-491e-99b0-da01ff1f3341";
  if (name) return uuidv5(name, MY_NAMESPACE);
  else return uuidv4();
};

export const truncateText = (text, length) => {
  if (!text) return '';
  if (!length) return text;

  if (text.length > length) {
    return text.substring(0, length) + "...";
  } else {
    return text;
  }
}

export const waitForStore = (selector, dependency, timeout) => {
  return new Promise((resolveState, rejectState) => {
    let unsubscribe = () => { };
    let resolved = false;
    let initialValue = selector(store.getState());

    if (timeout) setTimeout(() => {
      rejectState(`Waited for ${Math.round(timeout / 60)}s with no result from store`);
    }, timeout);

    unsubscribe = store.subscribe(() => {
      const state = store.getState();
      const targetValue = selector(state);

      if ((targetValue !== initialValue) && (!dependency || (dependency && dependency(targetValue)))) {
        unsubscribe();
        resolveState(targetValue);
        resolved = true;
      }
    });

    (async () => {
      while (!resolved) {
        await (() => new Promise((resolve, reject) => {
          setTimeout(() => resolve(true), 500);
        }))();
      }
    })();
  });
}

export const showNotification = ({ type, title, message, time, icon, config }) => {
  toast(<NotificationItem single {...{ type, title, message, time, icon, config }} />);
}

export const fromNowTimeString = (date, nowDate = Date.now(), rft = new Intl.RelativeTimeFormat(undefined, { numeric: "auto" })) => {
  const SECOND = 1000;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const WEEK = 7 * DAY;
  const MONTH = 30 * DAY;
  const YEAR = 365 * DAY;
  const intervals = [
    { ge: YEAR, divisor: YEAR, unit: 'year' },
    { ge: MONTH, divisor: MONTH, unit: 'month' },
    { ge: WEEK, divisor: WEEK, unit: 'week' },
    { ge: DAY, divisor: DAY, unit: 'day' },
    { ge: HOUR, divisor: HOUR, unit: 'hour' },
    { ge: MINUTE, divisor: MINUTE, unit: 'minute' },
    { ge: 30 * SECOND, divisor: SECOND, unit: 'seconds' },
    { ge: 0, divisor: 1, text: 'just now' },
  ];
  const now = typeof nowDate === 'object' ? nowDate.getTime() : new Date(nowDate).getTime();
  const diff = now - (typeof date === 'object' ? date : new Date(date)).getTime();
  const diffAbs = Math.abs(diff);
  for (const interval of intervals) {
    if (diffAbs >= interval.ge) {
      const x = Math.round(Math.abs(diff) / interval.divisor);
      const isFuture = diff < 0;
      return interval.unit ? rft.format(isFuture ? x : -x, interval.unit) : interval.text;
    }
  }
}

export function calculateDistance(coord1, coord2) {
  const [lon1, lat1] = coord1.map(parseFloat);
  const [lon2, lat2] = coord2.map(parseFloat);

  const R = 6371; // Radius of the Earth in km
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km
  return distance;
}

export function findDistinctItems(coords, threshold) {
  const distinctItems = [];

  for (let i = 0; i < coords.length; i++) {
      let isDistinct = true;
      for (let j = 0; j < coords.length; j++) {
          if (i !== j && calculateDistance(coords[i], coords[j]) <= threshold) {
              isDistinct = false;
              break;
          }
      }
      if (isDistinct) {
          distinctItems.push(coords[i]);
      }
  }

  return distinctItems;
}

export function buildEdgeBounds(cords) {
  const allLats = [];
  const allLngs = [];

  cords.forEach((cord) => {
      allLats.push(cord[1]);
      allLngs.push(cord[0]);
  });

  const ne = [Math.max(...allLngs), Math.max(...allLats)];
  const sw = [Math.min(...allLngs), Math.min(...allLats)];

  return [sw, ne];
}

export function djiTextFromMethod(group, method) {
  return `&bull; ${group} => ` + text.replace(/_/g, ' ');
}

export function isValidCoordinate(coord) {
  if(coord?.lat && coord?.long) {
    return coord?.lat > -90 && coord?.lat < 90 && coord?.long > -180 && coord?.long < 180
  }
  else if(coord?.latitude && coord?.longitude) {
    return coord?.latitude > -90 && coord?.latitude < 90 && coord?.longitude > -180 && coord?.longitude < 180
  }
  else if(coord?.[0] && coord?.[1]) {
    return coord[1] > -90 && coord[1] < 90 && coord[0] > -180 && coord[0] < 180
  }
  else {
    return false;
  }
}

export function convertToLngLat(coord) {
  if(!isValidCoordinate(coord)) return false;
  
  if(coord?.lat && coord?.long) {
    return [coord.long, coord.lat];
  }
  else if(coord?.latitude && coord?.longitude) {
    return [coord.longitude, coord.latitude];
  }
  else if(coord?.[0] && coord?.[1]) {
    return [coord[0], coord[1]];
  }
  else {
    return [];false
  }
}

export function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export function toDegrees(radians) {
  return radians * (180 / Math.PI);
}

export function calculateBearing(lat1, long1, lat2, long2) {
  const φ1 = toRadians(lat1);
  const φ2 = toRadians(lat2);
  const Δλ = toRadians(long2 - long1);

  const y = Math.sin(Δλ) * Math.cos(φ2);
  const x = Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);
  const θ = Math.atan2(y, x);

  let bearing = toDegrees(θ);
  bearing = (bearing + 360) % 360; // Normalize to 0-360 degrees

  return bearing;
}

export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function base64ToBlob(base64) {
  const byteString = atob(base64.split(",")[1]);
  const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
  const byteArray = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.charCodeAt(i);
  }
  return new Blob([byteArray], { type: mimeString });
}