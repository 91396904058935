import './AdsbStageOsd.sass'
import { MdArrowUpward } from 'react-icons/md'
import { TbAlertTriangleFilled, TbArrowBarToRight, TbArrowBarToUp, TbGridScan } from 'react-icons/tb'
import OsdFloatBox from '../../OsdFloatBox/OsdFloatBox'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { adsbCategoryProperties, airsenseVerticalTrendText, airsenseVerticalTrendTextShort, airsenseWarningLevelColor, airsenseWarningLevelText } from 'src/services/device/assets/airsenseConstants'
import { calculateBearing } from 'src/helper/utils'
import useDeviceTelemetries from 'src/services/device/common/useDeviceTelemetries'
import useSubDevice from 'src/services/device/common/useSubDevice'
import { useMemo } from 'react';
import { setLastExternalPosition } from 'src/components/DeviceMap/DeviceMapSlice'

function AdsbStageOsd({ entity, style }) {
  const dispatch = useDispatch();
  const airSense = useSelector(state => state.deviceService.deviceAirSense[entity?.id]);
  const { telemetries: subDeviceTelemetries } = useSubDevice(entity?.id);
  const counterColor = useMemo(() => airSense?.warnings?.find(warning => warning.warning_level > 2) ? 'red' : 'orange', [airSense?.warnings]);

  const handleWarningClick = (warning) => {
    dispatch(setLastExternalPosition({ triggerId: Date.now(), position: { latitude: warning.latitude, longitude: warning.longitude } }));
  };

  return !airSense?.warnings?.length ? null : (
    <OsdFloatBox title="Nearby Aircraft(s) Detected" icon={TbAlertTriangleFilled} counter={airSense.warnings.length} counterColor={counterColor} style={style} collapsable>
      <div className="adsb-stage-osd">
        <div className="warning-list">
          {airSense.warnings.map((warning, index) => (
            <div className={classNames("warning-item", 'warning-level-' + warning.warning_level)} key={warning.icao || index} onClick={() => handleWarningClick(warning)}>
              <div 
                className="color-bar" 
                style={{ background: airsenseWarningLevelColor[warning.warning_level] }}
                title={airsenseWarningLevelText[warning.warning_level] + ` (level ${warning.warning_level})`}
              ></div>
              <div className="target-details">
                <div className="title">{warning.adsb?.category ? `${adsbCategoryProperties[warning.adsb.category]?.description || '#' + warning.icao} (${warning.adsb.t})` : '#' + warning.icao}</div>
                <div className="info-list">
                  <div className="info-item" title="Callsign">
                    <TbGridScan className="icon" />
                    <span className="text">{warning.adsb?.flight || 'N/A'}</span>
                  </div>
                  <div className="info-item" title="Relative Altitude">
                    <TbArrowBarToUp className="icon" />
                    <span className="text">{warning.relative_altitude}m</span>
                  </div>
                  <div className="info-item" title="Distance">
                    <TbArrowBarToRight className="icon" />
                    <span className="text">{parseFloat(warning.distance / 1000).toPrecision(1)}km</span>
                  </div>
                </div>
              </div>
              <div className="target-direction">
                <div className="arrow" title="Relative Bearing">
                  <MdArrowUpward className='icon' style={{ transform: `rotate(${calculateBearing(subDeviceTelemetries?.latitude, subDeviceTelemetries?.longitude, warning.latitude, warning.longitude)}deg)` }} />
                </div>
                <div className="info"><span title="Heading">{warning.heading}°</span> <span title={airsenseVerticalTrendText[warning.vert_trend]}>{airsenseVerticalTrendTextShort[warning.vert_trend]}</span></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </OsdFloatBox>
  )
}

export default AdsbStageOsd