import './DockStage.sass'
import { StageElement } from '../../OperationDashboard'
import DockCamera from '../DockCamera/DockCamera'
import { useState } from 'react';
import classNames from 'classnames';
import StageStatusbar from '../StageStatusbar/StageStatusbar';
import { MdFolder } from 'react-icons/md';
import DeviceMediaList from '../DeviceMediaList/DeviceMediaList';
import useDeviceInfo from 'src/services/device/common/useDeviceInfo';

function DockStage({ elementType, entity, missionController, onMinimize, onVideoSourceSwitch }) {
  const [isMinimized, setIsMinimized] = useState();
  const [activeVideoSource, setActiveVideoSource] = useState();
  const deviceInfo = useDeviceInfo(entity?.id);
  const bucketInfo = deviceInfo?.details?.defaultDockBucket;

  const handleOnCameraMinimize = (state) => {
    setIsMinimized(state);
    typeof onMinimize === 'function' && onMinimize(state);
  }

  const handleOnVideoSourceSwitch = (source) => {
    setActiveVideoSource(source);
    typeof onVideoSourceSwitch === 'function' && onVideoSourceSwitch(source);
  }

  return (
    <div className={classNames("dock-stage", {'minimized': isMinimized})}>
      <div className="stage-main">
        {elementType === StageElement.DOCK && (
          <DockCamera 
            key={"dock-camera-" + entity?.id} 
            deviceId={entity?.id} 
            expanded={true} 
            pip={true} 
            missionController={missionController} 
            onMinimize={handleOnCameraMinimize} 
            onVideoSourceSwitch={handleOnVideoSourceSwitch}
          />
        )}
        {elementType === StageElement.DOCK_DRONE && (
          <DockCamera 
            key={"dock-drone-camera-"  + entity?.id} 
            deviceId={entity?.id} 
            expanded={true} 
            subDevice={true} 
            onMinimize={handleOnCameraMinimize} 
            onVideoSourceSwitch={handleOnVideoSourceSwitch}
          />
        )}
      </div>
      {bucketInfo ? (
        <StageStatusbar tabs={[
          { 
            id: 'device-media-list', 
            title: 'Captured Media', 
            icon: <MdFolder /> , 
            content: (setPanelHeaderLeft, setPanelHeaderTools) => (
              <DeviceMediaList 
                bucketId={bucketInfo.Id || bucketInfo.id} 
                bucketName={bucketInfo.name}
                bucketRegion={bucketInfo.region}
                deviceId={entity?.id}
                onPanelHeaderLeftChange={setPanelHeaderLeft}
                onPanelHeaderToolsChange={setPanelHeaderTools}
                path={deviceInfo?.serialNumber || ''}
              />
            ) 
          },
        ]} />
      ) : null}
    </div>
  )
}

export default DockStage